import React, { Component } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { TextInput, Button, HelperText, Text } from "react-native-paper";
import { connect } from "react-redux";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { createUser } from "../../actions/user.actions";
export class Register extends Component {
  state = {
    Email: "",
    Celular: "",
    Nombre: "",
    Apellidos: "",
    Contrasena: ""
  };
  _emailErrors = () => {
    return !this.state.Email.includes("@");
  };
  _celularErrors = () => {
    return (
      !this.state.Celular.length == 10 ||
      this.state.Celular.length > 10 ||
      this.state.Celular.length < 10
    );
  };
  _NombreErrors = () => {
    return !this.state.Nombre.length > 1;
  };
  _ApellidosErrors = () => {
    return !this.state.Nombre.length > 1;
  };
  _ContrasenaErrors = () => {
    return (
      !this.state.Contrasena.length == 8 || this.state.Contrasena.length < 8
    );
  };
  render() {
    const { navigation } = this.props;
    return (
      <ScrollView>
        <KeyboardAwareScrollView
          resetScrollToCoords={{ x: 0, y: 0 }}
          contentContainerStyle={styles.container}
          scrollEnabled={false}
        >
          <View styles={styles.col}>
            <View>
              <Text></Text>
            </View>
            <View style={styles.title}>
              <TextInput
                label="Correo"
                style={styles.TextInput}
                value={this.state.Email}
                mode="outlined"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={Email => this.setState({ Email })}
              />
              {/** 
              <HelperText
                type="error"
                visible={this._emailErrors()}
              >
                El Correo electronico es invalido
              </HelperText>
            */}
              <TextInput
                label="Celular"
                style={styles.TextInput}
                value={this.state.Celular}
                mode="outlined"
                maxLength={10}
                keyboardType="numeric"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={Celular => this.setState({ Celular })}
              />
              {/** 
              <HelperText
                type="error"
                visible={this._celularErrors()}
              >
                El numero de celular es invalido
              </HelperText>
              */}
              <TextInput
                label="Nombre"
                style={styles.TextInput}
                value={this.state.Nombre}
                mode="outlined"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={Nombre => this.setState({ Nombre })}
              />
              {/** 
              <HelperText
                type="error"
                visible={this._NombreErrors()}
              >
                El Campo Nombre no debe ser vacio
              </HelperText>
              */}
              <TextInput
                label="Apellidos"
                style={styles.TextInput}
                value={this.state.Apellidos}
                mode="outlined"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={Apellidos => this.setState({ Apellidos })}
              />
              {/** 
              <HelperText
                type="error"
                visible={this._ApellidosErrors()}
              >
                El Campo Apellidos no debe ser vacio
              </HelperText>
              **/}
              <TextInput
                label="Contraseña"
                style={styles.TextInput}
                value={this.state.Contrasena}
                mode="outlined"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={Contrasena => this.setState({ Contrasena })}
              />
              {/** 
              <HelperText
                type="error"
                visible={this._ContrasenaErrors()}
              >
                El Campo Contraseña debe contener porlo menos 8 caracteres
              </HelperText>
              */}
            </View>
          </View>
          <View style={styles.row}>
            <View>
              <Button
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onPress={() =>
                  navigation.navigate("Login", { screen: "Login" })
                }
              >
                Cancelar
              </Button>
            </View>
            <View>
              <Button
                mode="contained"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                disabled={
                  this._NombreErrors() ||
                  this._ApellidosErrors() ||
                  this._celularErrors() ||
                  this._emailErrors() ||
                  this._ContrasenaErrors()
                }
                onPress={() =>
                  navigation.navigate("RegisterMap", {
                    screen: "RegisterMap",
                    params: { user: this.state }
                  })
                }
              >
                Siguiente
              </Button>
            </View>
          </View>
          <Text></Text>
        </KeyboardAwareScrollView>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    paddingTop: 30,
    flexDirection: "row",
    justifyContent: "center",
    overlayColor: "red"
  },
  col: {
    paddingTop: 30,
    flexDirection: "column",
    justifyContent: "center",
    overlayColor: "red"
  },
  TextInput: {
    margin: 10
  },
  title: {
    paddingTop: 80,
    paddingLeft: 20,
    paddingRight: 20
  }
});

const mapStateToProps = ({ userReducers }) => {
  return { userReducers };
};

const mapDispatchToProps = { createUser };

export default connect(mapStateToProps, mapDispatchToProps)(Register);
