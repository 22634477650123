import React, { Component } from "react";
import { Text, View, StyleSheet, Image } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Button, TextInput, Menu } from "react-native-paper";
import {loginRAction} from '../../actions/user.actions'
import logo from "../../../assets/logo2.png";
import { connect } from 'react-redux'

export class login extends Component {
  state = {
    Email: "",
    Contrasena: "",
    visible: false
  };
  loginOnPress = async () => {
    this.setState({visible: true})
    const {loginRAction} = this.props
    await loginRAction(this.state.Email, this.state.Contrasena, this.props.userReducer.tokenNotification);
    !this.props.userReducer.error ==202? this._showDialog(): this._hideDialog()
    this.setState({visible: false})

  };
  _showDialog = () => this.setState({ visible: true });

  _hideDialog = () => this.setState({ visible: false });
  render() {
    const {navigation} = this.props
    return (
      <KeyboardAwareScrollView
        resetScrollToCoords={{ x: 0, y: 0 }}
        scrollEnabled={false}
        contentContainerStyle={styles.container}
      >
        <View style={styles.row}>
          <Image style={styles.Image} source={logo} />
        </View>
        <TextInput
          mode="outlined"
          theme={{ colors: { primary: "red", underlineColor: "transparent" } }}
          label="Correo"
          style={styles.InputEmail}
          value={this.state.Email}
          onChangeText={Email => this.setState({ Email })}
          selectionColor="red"
          underlineColor="red"
        />
        <TextInput
          mode="outlined"
          theme={{ colors: { primary: "red", underlineColor: "transparent" } }}
          label="Contraseña"
          style={styles.InputPassword}
          secureTextEntry
          value={this.state.Contrasena}
          onChangeText={Contrasena => this.setState({ Contrasena })}
          selectionColor="red"
        />
        <View style={styles.row}>
          <Button
            mode="Outlined"
            theme={{
              colors: { primary: "red", underlineColor: "transparent" }
            }}
            style={styles.recordar}
            onPress={() =>
              navigation.navigate("RecordarCon", { screen: "RecordarCon" })
            }
          >
            recordar
          </Button>
          <Button
            mode="contained"
            theme={{
              colors: { primary: "red", underlineColor: "transparent" }
            }}
            style={styles.Login}
            onPress={this.loginOnPress}
            loading={this.state.visible}
            disabled={this.state.visible}
          >
            {this.state.visible ? "Ingresando" : "Ingresar"}
          </Button>
        </View>
        <View style={styles.container}>
            <Button 
              icon="card-text-outline"
              theme={{ colors: { primary: 'red',underlineColor:'transparent',}}}  
              mode="text" 
              onPress={() => navigation.navigate('Register', { screen: 'Register' })}
            >
              Registrate
            </Button>
          </View>
          {/* <View>
            <Button style={styles.botonPoliticas} theme={{ colors: { primary: 'red',underlineColor:'transparent',}}}  mode="text" onPress={() => navigation.navigate('Politicas', { screen: 'Politicas' })}>
              Politicas de uso
            </Button>
          </View> */}
      </KeyboardAwareScrollView>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    paddingTop: 30
  },
  row: {
    flexDirection: "row",
    justifyContent: "center",
    overlayColor: "red",
    paddingTop: 20

  },
  Image: {
    width: 200,
    height: 200
  },
  InputEmail: {
    marginHorizontal: 30,
    color: "red",
    backgroundColor: "#eee",
    borderColor: "#5d05d5",
    marginTop: 15
  },
  InputPassword: {
    marginHorizontal: 30,
    marginTop: 15
  }
});
const mapStateToProps = ({userReducer}) => {
  return {
    userReducer,
  };
};


const mapDispatchToProps = {
  loginRAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(login);