import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons';
import HomeStackScreen from './homeStackScreen'
import pedidoStackScreen from './pedidoStackScreen'
import settingStackScreen from './settingStackScreen'
import ProductosStackScreen from './ProductosStackScreen'
import OrdenesStackScreen from './OrdenesStackScreen'
import { connect } from 'react-redux'
import LoginStackScreen from './loginStackScreen'
import {setTokenNotification} from '../actions/user.actions'
const Tab = createBottomTabNavigator();

class Router extends React.Component {
  async componentDidMount() {
    const {setTokenNotification} = this.props
    console.log(this.props.tokenNotification)
    this.props.tokenNotification!=""||this.props.tokenNotification!=null?await setTokenNotification(this.props.tokenNotification):null
  }
  render() {
    console.log("userReducer "+ JSON.stringify(this.props.userReducer))
    return (
      <NavigationContainer>
        {this.props.userReducer.token == "" ?
          <LoginStackScreen /> :

          this.props.userReducer.type == "VENDEDOR" ?
            <Tab.Navigator
              screenOptions={({ route }) => ({
                tabBarIcon: ({ focused, color, size }) => {
                  let iconName;
                  if (route.name === 'Productos') {
                    iconName = 'list-circle-outline';
                  } else if (route.name === 'Ordenes') {
                    iconName = 'fast-food-outline';
                  } else if (route.name === 'Ajustes') {
                    iconName = 'ios-options';
                  }
                  return <Ionicons name={iconName} size={35} color={color} />;
                },
              })}
              tabBarOptions={{
                activeTintColor: 'white',
                inactiveTintColor: 'black',
                style: {
                  backgroundColor: 'red',
                }
              }}>
              <Tab.Screen name="Productos" component={ProductosStackScreen} options={{ tabBarLabel: 'Productos' }} />
              {this.props.userReducer.isActive?<Tab.Screen name="Ordenes" component={OrdenesStackScreen} options={{ tabBarLabel: 'Ordenes' }} />:null}
              <Tab.Screen name="Ajustes" component={settingStackScreen} options={{ tabBarLabel: 'Ajustes' }} />
            </Tab.Navigator>
            :
            <Tab.Navigator
              screenOptions={({ route }) => ({
                tabBarIcon: ({ focused, color, size }) => {
                  let iconName;
                  if (route.name === 'Inicio') {
                    iconName = 'home';
                  } else if (route.name === 'Pedidos') {
                    iconName = 'fast-food-outline';
                  } else if (route.name === 'Ajustes') {
                    iconName = 'ios-options';
                  }
                  return <Ionicons name={iconName} size={35} color={color} />;
                },
              })}
              tabBarOptions={{
                activeTintColor: 'white',
                inactiveTintColor: 'black',
                style: {
                  backgroundColor: 'red',
                }
              }}>
              <Tab.Screen name="Inicio" component={HomeStackScreen} options={{ tabBarLabel: 'Home' }} />
              <Tab.Screen name="Pedidos" component={pedidoStackScreen} options={{ tabBarLabel: 'Pedidos' }} />
              <Tab.Screen name="Ajustes" component={settingStackScreen} options={{ tabBarLabel: 'Ajustes' }} />
            </Tab.Navigator>
        }
      </NavigationContainer>
    )
  };
}
const mapStateToProps = ({ userReducer }) => {
  return { userReducer }
}
const mapDispatchToProps = {
  setTokenNotification
}
export default connect(mapStateToProps, mapDispatchToProps)(Router);