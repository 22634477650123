export const TRAER_TODOS = 'TRAER_TODOS_PRODUCTOS';
export const TRAER_POR_USUARIO = 'TRAER_POR_USUARIO_PRODUCTOS';
export const CARGANDO = 'CARGANDO_PRODUCTOS';
export const ACTUALIZAR = 'ACTUALIZAR_PRODUCTOS';
export const ERROR = 'ERROS_PRODUCTOS';
export const BUSCADOR = 'BUSCADOR_PRODUCTOS';
export const TRAER_POR_ID_TIENDA_PRODUCTOS = 'TRAER_POR_ID_TIENDA_PRODUCTOS';
export const LIMPIAR_PRODUCTOS = 'LIMPIAR_PRODUCTOS';
export const TRAER_POR_ID_TIENDA_PRODUCTOS_HEADER = 'TRAER_POR_ID_TIENDA_PRODUCTOS_HEADER';
export const CARD_LIST_ADD = 'CARD_LIST_ADD';
export const CARD_LIST_DELETE = 'CARD_LIST_DELETE';
export const LIMPIAR_CART = 'LIMPIAR_CART';
export const TRAER_POR_ID_TIENDA_PRODUCTOS_ADMIN = 'TRAER_POR_ID_TIENDA_PRODUCTOS_ADMIN';