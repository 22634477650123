import React, { Component } from "react";
import { Text, View, StyleSheet, Dimensions, Image } from "react-native";
import { Button, TextInput } from "react-native-paper";
import * as Location from "expo-location";
import {createUser, loginRAction} from './../../actions/user.actions'
import MapView from "react-native-maps";
// import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import pointInPolygon from "point-in-polygon";
import Dialog from "react-native-dialog";
import { connect } from 'react-redux'

export class registerMap extends Component {
  state = {
    latitude: 24.322831,
    longitude: -107.3672667,
    loading: false,
    visible: false,
    Referencias: '',
    marcador: {}

  };
  async componentDidMount() {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      setErrorMsg("Para crear una orden es necesario tener permisos de localizacion, favor de activar para continuar.");
    }
    let location = await Location.getCurrentPositionAsync({});
    this.setState({ latitude: location.coords.latitude });
    this.setState({ longitude: location.coords.longitude });
  }
  onSave = async () => {
    const { createUser, loginRAction } = this.props;
    this.setState({loading: true})
    await createUser(
      this.props.route.params.params.user,
      this.state.marcador,
      this.state.region,
      this.state.Referencias
    );
    if (this.props.userReducer.error == 202) {
      await loginRAction(
        this.props.route.params.params.user.Email,
        this.props.route.params.params.user.Contrasena
      );
    } else {
      this.setState({ visible: true });
    }
    this.setState({loading: false})
  };
  onRegionChange = region => {
    var polygon = [
      [24.327682, -107.36548],
      [24.330334, -107.363067],
      [24.328608, -107.352594],
      [24.332691, -107.345458],
      [24.322379, -107.354476],
      [24.318739, -107.359418],
      [24.321854, -107.369336]
    ];
    // console.log(pointInPolygon([region.latitude, region.longitude], polygon));
    this.setState({
      marcador:region,
    });
  };
  render() {
    return (
      
        <View style={styles.container}>
          <MapView
            style={styles.map} 
            showsUserLocation={true}
            onRegionChangeComplete={this.onRegionChange}
            initialRegion={{
              latitude: this.state.latitude,
              longitude: this.state.longitude,
              latitudeDelta: 0.001,
              longitudeDelta: 0.001
            }}
          ></MapView>
          <View style={styles.mapMarkerContainer}>
            <Button
              icon={() => (
                <Image
                  source={require("../../../assets/alfiler.png")}
                  style={{ width: 42, height: 42 }}
                />
              )}
            ></Button>
          </View>
          <View style={styles.deatilSection}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginBottom: 20
              }}
            >
              Mueve el mapa para localizarte
            </Text>
            <View style={styles.btnContainer}>
              <View>
                <TextInput
                  label="Referencias"
                  value={this.state.Referencias}
                  mode="outlined"
                  theme={{
                    colors: { primary: "red", underlineColor: "transparent" }
                  }}
                  onChangeText={Referencias => this.setState({ Referencias })}
                />
              </View>
              <View style={styles.row}>
                <View>
                  <Button
                    theme={{
                      colors: { primary: "red", underlineColor: "transparent" }
                    }}
                    onPress={() =>
                      navigation.navigate("Login", { screen: "Login" })
                    }
                  >
                    Cancelar
                  </Button>
                </View>
                <View>
                  <Button
                    mode="contained"
                    theme={{
                      colors: { primary: "red", underlineColor: "transparent" }
                    }}
                    onPress={this.onSave}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                  >
                    {this.state.visible ? "Cargando" : "Crear"}
                  </Button>
                </View>
              </View>
            </View>
          </View>
          <View>
            <Dialog.Container visible={this.state.visible}>
              <Dialog.Title>Algo salio mal</Dialog.Title>
              <Dialog.Description>
                Email U/O Celular existentes con otra cuenta.
              </Dialog.Description>
              <Dialog.Button label="Cerrar" onPress={()=>this.setState({visible: false})} />
            </Dialog.Container>
          </View> 
        </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
  mapMarkerContainer: {
    left: "38%",
    position: "absolute",
    top: "20%"
  },
  btnContainer: {
    width: Dimensions.get("window").width,
    position: "absolute",
    paddingTop: 40,
    left: 10
  }
});
const mapStateToProps = ({userReducer}) => {
  return {
    userReducer
  };
};

const mapDispatchToProps = {
  createUser,
  loginRAction
}

export default connect(mapStateToProps, mapDispatchToProps)(registerMap)
