/* eslint-disable */
import {LOGIN_USER, ERROR_USER, LOGOUT_CLEAR_USER,EDITAR_MARCADOR_USER,EDITAR_MARCADOR_REFERENCIAS_USER, UPDATE_AVATAR, SET_TOKEN_NOTIFICATION} from '../types/user.type'
import {CLEAR_ORDENES} from '../types/orden.types'
import axios from 'axios';
export const loginRAction = (email, password, tokenNotification) => async dispatch => {
  let data = JSON.stringify({
    email,
    password,
    tokenNotification
  });
  try {
    let respuesta = await axios.post(
      'https://api.disy.com.mx/api/signin',
      data,
      {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({
      type: LOGIN_USER,
      payload: respuesta.data.todos,
    });
    dispatch({
      type: ERROR_USER,  
      payload: 202,
    });
  } catch (error) {
    console.log(error)

    dispatch({
      type: ERROR_USER,  
      payload: 404,
    });
  }
};
export const logout = () => dispatch => {
  dispatch({
    type: LOGOUT_CLEAR_USER,
  });
  dispatch({
    type: CLEAR_ORDENES
  })
};

export const createUser = (user, marcador, localizacion, Referencias) => async (dispatch) => {
  const data = {
    user,
    marcador,
    localizacion,
    Referencias
  }
  try {
    await axios.post(
      'https://api.disy.com.mx/api/signup',
      data,
      {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({
      type: ERROR_USER,
      payload: 202,
    });
    return 202
  } catch (error) {
    dispatch({
      type: ERROR_USER,
      payload: 404,
    });
    return 404
  }
}

export const recordarContrasena = (email) => async (dispatch) => {
  try {
    await axios.get(`https://api.disy.com.mx/api/sendRestablecerContrasenia/${email}`)
  } catch {
    dispatch({
			type: ERROR,
			payload: 'Algo salió mal, intente más tarde.'
		})
  }

}

export const editarMarcadorAction = (token, marcador, Referencias) => async dispatch => {
  try {
    let respuesta = await axios.post(
      'https://api.disy.com.mx/api/restablecerMarcador',
      {marcador:marcador, referencias: Referencias},
      {
      headers: {
        'Content-Type': 'application/json',
        'authorization': token
      },
    });
    dispatch({
      type: EDITAR_MARCADOR_USER,
      payload: respuesta.data.marcador,
    });
    dispatch({
      type: EDITAR_MARCADOR_REFERENCIAS_USER,
      payload: respuesta.data.referencias
    })
    dispatch({
      type: ERROR_USER,  
      payload: 202,
    });
    return 202
  } catch (error) {
    dispatch({
      type: ERROR_USER,  
      payload: error,
    });
    return 404
  }
};
export const updateAvarar = (avatar, token) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_AVATAR,
      payload: avatar
    })
    let respuesta = await axios.post(
      'https://api.disy.com.mx/api/updateavatar',
      {avatar},
      {
      headers: {
        'Content-Type': 'application/json',
        'authorization': token
      },
    });
    return respuesta
  } catch (error) {
    return error
  }
}
export const updateLogo = (logo, token, nameSpace) => async dispatch => {
  try {
    // dispatch({
    //   type: UPDATE_PORTADA,
    //   payload: portada
    // })
    let respuesta = await axios.post(
      'https://api.disy.com.mx/api/updateLogo',
      {logo, nameSpace},
      {
      headers: {
        'Content-Type': 'application/json',
        'authorization': token
      },
    });
    return respuesta
  } catch (error) {
    return error
  }
}
export const updatePortada = (portada, token, nameSpace) => async dispatch => {
  try {
    // dispatch({
    //   type: UPDATE_PORTADA,
    //   payload: portada
    // })
    let respuesta = await axios.post(
      'https://api.disy.com.mx/api/updatePortada',
      {portada, nameSpace},
      {
      headers: {
        'Content-Type': 'application/json',
        'authorization': token
      },
    });
    return respuesta
  } catch (error) {
    return error
  }
}
export const setTokenNotification = (token) => (dispatch) => {
  dispatch({
    type: SET_TOKEN_NOTIFICATION,
    payload: token
  })
}