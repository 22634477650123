import React, { Component } from 'react'
import { Text, View, Image, ActivityIndicator, StyleSheet } from 'react-native'

class Images extends Component {
  state = {
    loadingImageHeader: true
  };
  render() {
    return (
      <View>
        <Image
          onLoadEnd={() => {this.setState({loadingImageHeader: false})}}
          source={{ uri: this.props.url }}
          style={this.props.style}
          resizeMode={this.props.resizeMode}
        />
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={this.state.loadingImageHeader}
        />
      </View>
    )
  }
}
const styles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }
})
export default Images
