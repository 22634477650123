import React from 'react'
import { Text, View, StyleSheet, Image } from "react-native";
import { Card, Title, Paragraph } from "react-native-paper";

const Promociones = ({data}) => {
  return (
    <View style={styles.container}>
      <Card
        style={styles.card}
      >
        <Card.Cover
          source={{ uri: `https://file.rubengarcia.me/api/image/disystage/local2/AF76633B-BC10-457C-881B-A27738992011-482.png`}}
          style={{ margin: 0 }}
        />
        <Card.Content>
          {/* <Title>hola</Title> */}
          {/* <Paragraph>{item.descripcion}</Paragraph>
          <Paragraph>{item.tipoLocal}</Paragraph> */}
        </Card.Content>
      </Card>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    textAlign: "center",
    flexDirection: "row"
  },
  card: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    width: 200
  },
});
export default Promociones
