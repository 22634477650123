import React, { Component } from "react";
import { Text, View, Image, StyleSheet, Dimensions } from "react-native";
import { Button, TextInput } from 'react-native-paper'
import MapView from 'react-native-maps'
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { connect } from 'react-redux'
import pointInPolygon from 'point-in-polygon'
import { editarMarcadorAction } from '../../actions/user.actions'
import Dialog from "react-native-dialog";

export class editDirection extends Component {
  state = {
    region: this.props.userReducer.marcador,
    Referencias: this.props.userReducer.referencias,
    dialog: false
  }
  onRegionChange = region => {
    var polygon = [
      [24.327682, -107.36548],
      [24.330334, -107.363067],
      [24.328608, -107.352594],
      [24.332691, -107.345458],
      [24.322379, -107.354476],
      [24.318739, -107.359418],
      [24.321854, -107.369336]
    ];
    // console.log(pointInPolygon([region.latitude, region.longitude], polygon));
    this.setState({ region });
  };
  onSave = async () => {
    const { editarMarcadorAction, navigation, ruta } = this.props;
    this.setState({ loading: true, visible: true })
    let response = await editarMarcadorAction(
      this.props.userReducer.token,
      this.state.region,
      this.state.Referencias
    );
    if (response == 202) {
      this.setState({ loading: false, visible: false })
      navigation.navigate(ruta, { screen: ruta })
    } else {
      this.setState({ dialog: true })
      this.setState({ loading: false, visible: false })

    }
  };
  render() {
    const { navigation, ruta } = this.props
    return (
      <KeyboardAwareScrollView
        resetScrollToCoords={{ x: 0, y: 0 }}
        scrollEnabled={true}
        contentContainerStyle={styles.container}
      >
        <View>
          <View style={{height: 320}}>
            <MapView
              style={styles.map}
              showsUserLocation={true}
              onRegionChangeComplete={this.onRegionChange}
              initialRegion={this.state.region}
            ></MapView>
            <View style={styles.mapMarkerContainer}>
              <Button
                icon={() => (
                  <Image
                    source={require("../../../assets/alfiler.png")}
                    style={{ width: 42, height: 42 }}
                  />
                )}
              ></Button>
            </View>
          </View>
          <View style={styles.btnContainer}>
            <View>
              <TextInput
                label="Referencias"
                value={this.state.Referencias}
                mode="outlined"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={Referencias => this.setState({ Referencias })}
              />
            </View>
            <View style={styles.row}>
              <View style={styles.button}>
                <Button
                  theme={{
                    colors: { primary: "red", underlineColor: "transparent" }
                  }}
                  onPress={() =>
                    navigation.navigate(ruta, { screen: ruta })
                  }
                >
                  Cancelar
                  </Button>
              </View>
              <View style={styles.button}>
                <Button
                  mode="contained"
                  theme={{
                    colors: { primary: "red", underlineColor: "transparent" }
                  }}
                  onPress={this.onSave}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                >
                  {this.state.visible ? "Cargando" : "Editar"}
                </Button>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Dialog.Container visible={this.state.dialog}>
            <Dialog.Title>Algo salio mal</Dialog.Title>
            <Dialog.Description>
              Favor de intentar mas tarde
          </Dialog.Description>
            <Dialog.Button label="Cerrar" onPress={() => this.setState({ dialog: false })} />
          </Dialog.Container>
        </View>
      </KeyboardAwareScrollView>
    );
  }
}
const styles = StyleSheet.create({
  button: {
    margin: 5
  },
  map: {
    width: Dimensions.get("window").width,
    height: "120%",
  },
  mapMarkerContainer: {
    left: "38%",
    position: "absolute",
    top: "50%"
  },
  btnContainer: {
    padding: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 20
  }
})

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer
  };
};

const mapDispatchToProps = {
  editarMarcadorAction
}

export default connect(mapStateToProps, mapDispatchToProps)(editDirection);
