import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, Image } from "react-native";
import { ActivityIndicator, Card } from "react-native-paper";
import enviado from "../../../assets/enviado.png";
import cancelar from "../../../assets/cancelar.png";
import aceptado from "../../../assets/aceptado.png";


const OrdenItem = ({item}) =>{
  const calTotal = (productos) => {
    let total = 0;
    productos.forEach(item => {
      total = total + item.precio;
    });
    return total;
  };
  // const [minute, setMinute] = useState(0)
  // const [second, setSecond] = useState(0)
  // const [segundos, setSegundos] = useState(1)
  // const [duracion, setDuracion] = useState(1)

  // useEffect(() => {
  //   let dif=59;
  //   let diffse = moment().diff(moment(item.created_at).add(duracion, "minute"), "s");
      
  //   let diffmi = moment().diff(
  //     moment(item.created_at).add(duracion, "minute"),
  //     "minute"
  //   );
  //   setSecond(Math.round(diffse % 0x3c).toString())
  //   setMinute(diffmi)

  //   let refrehCount = setInterval(() => {
  //     let diffse = moment().diff(moment(item.created_at).add(duracion, "minute"), "s");
      
  //     let diffmi = moment().diff(
  //       moment(item.created_at).add(duracion, "minute"),
  //       "minute"
  //     );
  //     setSecond(Math.round(diffse % 0x3c).toString())
  //     setMinute(diffmi)
  //     if (segundos <= 0) {
  //       console.log('stop')
  //       clearInterval(refrehCount);
  //     }
  //   }, 1000);
  // }, []);

    return (
      <Card style={styles.card}>
        <View style={styles.container}>
          <View style={styles.icon}>
            {item.statusventa == "ENVIADO" ? (
              <Image source={enviado} style={{ width: 50, height: 50 }} />
            ) : null}
            {item.statusventa == "PENDIENTE" ? (
              <ActivityIndicator animating={true} color={"red"} size={50} />
            ) : null}
            {item.statusventa == "ACEPTADA" ? (
              <Image source={aceptado} style={{ width: 50, height: 50 }} />
            ) : null}
            {item.statusventa == "CANCELADA" ? (
              <Image source={cancelar} style={{ width: 50, height: 50 }} />
            ) : null}
          </View>
          <View style={styles.text}>
            <Text>Estatus: {item.statusventa}</Text>
            <Text>No. Orden: {item.nomOrden}</Text>
            <Text>Total: ${calTotal(item.productos).toFixed(2)}</Text>
          </View>
          {/* {item.statusventa=="PENDIENTE"?
            <View style={styles.row}>
              <Text style={styles.temp}>0{item.timeDif.min}:</Text>
              <Text style={styles.temp}>
                {item.timeDif.seg > 9
                  ? item.timeDif.seg
                  : `0${item.timeDif.seg}`}
              </Text>
            </View>
          :null} */}
        </View>
      </Card>
    );
}
const styles = StyleSheet.create({
  container: {
    textAlign: "center",
    flexDirection: "row"
  },
  temp: {
    fontSize: 18
  },
  icon: {
    padding: 18
  },
  text: {
    padding: 10
  },
  card: {
    marginTop: 18,
    marginLeft: 18,
    marginRight: 18
  },
  row: {
    flexDirection: "row",
    right: 12,
    margin: 18,
    position: "absolute"
  }
});
export default OrdenItem;
