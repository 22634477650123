import React, { Component, useRef } from 'react'
import Router from './src/Routers'
import { createStore, applyMiddleware } from 'redux'
import { Provider as StoreProvider, connect } from 'react-redux';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { store, persistor } from './src/store'
import { PersistGate } from 'redux-persist/integration/react'

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

export class App extends Component {
  state = {
    token: null
  }
  async componentDidMount(){
    let notificationListener;
    let responseListener;
    await this.registerForPushNotificationsAsync().then((token) =>
      this.setState({token})
    );
    await Notifications.getPermissionsAsync();
      
      return () => {
        Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }
  registerForPushNotificationsAsync=async()=> {
    let token;
    if (Constants.isDevice) {
      const {
        status: existingStatus,
      } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);
    } else {
      alert('Must use physical device for Push Notifications');
    }
  
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
    return token;
  }

  render() {
    return (
      <StoreProvider store={store}>
        <PersistGate
          persistor={persistor}
        >

          <Router tokenNotification={this.state.token} />
        </PersistGate>
      </StoreProvider>
    )
  }
}

export default App;
