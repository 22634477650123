import React, { Component } from "react";
import {
  View,
  Button,
  Image,
  StyleSheet,
  ScrollView,
  ActivityIndicator
} from "react-native";
import Dialog from "react-native-dialog";
import {
  TextInput,
  Button as ButtonP,
  RadioButton,
  Text,
  ProgressBar
} from "react-native-paper";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { addProduct, editProduct, listByIdStore } from "../../actions/productos.actions";
import { connect } from "react-redux";
import UploadImage from "../../components/UploadImage";

export class AddProduct extends Component {
  state = {
    _id: "",
    nombre: "",
    imageServe: "",
    descripcion: "",
    precio: "",
    categoria: "",
    disponible: false,
    visible: false,
    dialog: false
  };
  componentDidMount() {
    if (this.props.Type == "EDIT") {
      const {
        Nombre,
        _id,
        descripcion,
        disponible,
        img,
        precio,
        categoria
      } = this.props.route.params.item2;
      this.setState({
        nombre: Nombre,
        _id,
        descripcion,
        disponible,
        imageServe: img,
        precio: JSON.stringify(precio),
        categoria: categoria.join()
      });
    } else {
      this.setState({
        _id: "",
        nombre: "",
        imageServe: "",
        descripcion: "",
        precio: "",
        categoria: "",
        disponible: false,
        visible: false,
        dialog: false
      })
    }
  }
  getUrlImage = imageServe => {
    this.setState({ imageServe, visibleImage: true });
  };
  onSave = async () => {
    this.getdata;
    const {
      addProduct,
      userReducer: { token },
      navigation,
      editProduct,
      listByIdStore,
    } = this.props;
    const {
      nombre,
      imageServe,
      descripcion,
      precio,
      disponible,
      categoria,
      _id
    } = this.state;
    this.setState({ visible: true });

    let resAddProd
    if (this.props.Type != "EDIT") {
    resAddProd = await addProduct(token, {
      Nombre: nombre,
      img: imageServe,
      descripcion,
      precio,
      disponible,
      categoria
    });
    } else {
      resAddProd = await editProduct(token, {
        _id,
        Nombre: nombre,
        img: imageServe,
        descripcion,
        precio,
        disponible,
        categoria
      });
    }
    if (resAddProd) {
      await listByIdStore(token);
      navigation.navigate("Productos", {
        screen: "Productos",
      })
    } else {
      this.setState({ dialog: true });
    }
    this.setState({ visible: false });
  };
  render() {
    return (
      <>
        <ScrollView>
          <KeyboardAwareScrollView
            resetScrollToCoords={{ x: 0, y: 0 }}
            scrollEnabled={false}
            >
            <View style={styles.container}>
              <TextInput
                label="Nombre del producto"
                value={this.state.nombre}
                mode="outlined"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={nombre => this.setState({ nombre })}
              />
              <View style={styles.image}>
                <Image
                  source={{ uri: `https://file.rubengarcia.me/api/image/disystage/${this.props.userReducer.nameSpace}/${this.state.imageServe}?w=300&q=40` }}
                  style={{
                    width: 180,
                    height: 180,
                    borderRadius: 10
                  }}
                />
              </View>
              <UploadImage
                getUrlImage={this.getUrlImage}
                image={this.state.imageServe}
                nameSpace={this.props.userReducer.nameSpace}
                token={this.props.userReducer.token}
              />
              <TextInput
                label="Descripcion"
                value={this.state.descripcion}
                mode="outlined"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={descripcion => this.setState({ descripcion })}
              />
              <TextInput
                label="precio"
                value={this.state.precio}
                mode="outlined"
                keyboardType="numeric"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={precio => this.setState({ precio })}
              />
              <TextInput
                label="categoria"
                value={this.state.categoria}
                mode="outlined"
                theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }}
                onChangeText={categoria => this.setState({ categoria })}
              />
              <RadioButton.Group
                onValueChange={newValue =>
                  this.setState({ disponible: newValue })
                }
                value={this.state.disponible}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <RadioButton value={true} />
                  <Text>Activo</Text>
                </View>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <RadioButton value={false} />
                  <Text>Inactivo</Text>
                </View>
              </RadioButton.Group>
              <View>
                <ButtonP
                  mode="contained"
                  theme={{
                    colors: { primary: "red", underlineColor: "transparent" }
                  }}
                  onPress={this.onSave}
                  loading={this.state.visible}
                  disabled={this.state.visible}
                >
                  {this.state.visible ? "Cargando" : "Guardar"}
                </ButtonP>
              </View>
            </View>
          </KeyboardAwareScrollView>
        </ScrollView>
        <View>
          <Dialog.Container visible={this.state.dialog}>
            <Dialog.Title>Algo salio mal</Dialog.Title>
            <Dialog.Description>Favor de intentar mas tarde</Dialog.Description>
            <Dialog.Button
              label="Cerrar"
              onPress={() => this.setState({ dialog: false })}
            />
          </Dialog.Container>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    display: "flex"
  },
  image: {
    display: "flex",
    padding: 10,
    alignItems: "center"
  }
});

const mapStateToProps = ({ userReducer }) => ({
  userReducer
});

const mapDispatchToProps = {
  addProduct,
  editProduct,
  listByIdStore,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
