import * as React from 'react';
import { Button, Text, View, SafeAreaView } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import Home from '../modules/Home'
import HomeDetails from '../modules/HomeDetails'
import ProductosDetailsHome from '../modules/ProductosDetailsHome'
import Cart from '../modules/Cart'
import EditDirection from '../modules/EditDirection'
import Title from '../components/Title'
function ProductosDetailsHomeScreen({ navigation, route }) {
  return (
    <View style={{ flex: 1 }}>
      <Title options={true} navigation={navigation} routerBack="DetailsHome" routerBackScreen="DetailsHome" />
      <ProductosDetailsHome navigation={navigation} route={route} />
    </View>
  );
}

function DetailsHomeScreen({ navigation, route }) {
  return (
    <View style={{ flex: 1 }}>
      <Title options={true} navigation={navigation} routerBack="Home" routerBackScreen="Home" />
      <HomeDetails navigation={navigation} route={route} />
    </View>
  );
}

function HomeScreen({ navigation }) {
  return (
    <View style={{ flex: 1 }}>
      <Title />
      <Home navigation={navigation} />
    </View>
  );
}

function ModalScreen({ navigation, route }) {
  return (
    <View style={{ flex: 1 }}>
      <Title options={true} navigation={navigation} routerBack="DetailsHome" routerBackScreen="DetailsHome" />
      <Cart navigation={navigation} route={route}/>
    </View>
  );
}

function EditDirectionScreen({ navigation, route }) {
  return (
    <View style={{ flex: 1 }}>
      <Title options={true} navigation={navigation} routerBack="modal" routerBackScreen="modal" />
      <EditDirection navigation={navigation} route={route} ruta={'modal'}/>
    </View>
  );
}

const HomeStack = createStackNavigator();

export default function HomeStackScreen(props) {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen name="Home" component={HomeScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerTintColor: '#fff',
      }} />
      <HomeStack.Screen name="DetailsHome" component={DetailsHomeScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
      }} />
      <HomeStack.Screen name="ProductosDetailsHome" component={ProductosDetailsHomeScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
      }} />
      <HomeStack.Screen name="modal" component={ModalScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
      }} />
      <HomeStack.Screen name="EditDirection" component={EditDirectionScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
      }} />
      </HomeStack.Navigator>
  );
}