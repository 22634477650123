import React, { Component } from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import {
  Headline,
  Paragraph,
  Surface,
  IconButton,
  Button,
  Title
} from "react-native-paper";
import Dialog from "react-native-dialog";
import { connect } from "react-redux";
import * as Location from "expo-location";
import { cardListDelete } from "../../actions/productos.actions";
import { crearOrden, seeDetailsOrden, orderById } from "../../actions/orden.actions";
import Cerrar from "../../../assets/cerrar2.png";
import Mapa from "../../../assets/mapa.png";
import carroCompra from "../../../assets/carro-de-la-compra.png";
import { RadioButton } from 'react-native-paper';
import Images from "../../components/Images"
export class cart extends Component {
  state = {
    productList: [],
    visible: false,
    loadding: false,
    region: {},
    dialog: false,
    geolocalization: false,
    tipoEnvio: 1
  };
  async componentDidMount() {
    this.listCart();
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      setErrorMsg('Favor de avilitar los permisos de localizacion para tener una buena experiencia de usuario');
      return;
    }
    let location = await Location.getCurrentPositionAsync({});
    this.setState({
      region: {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude
      }
    });
    this.setState({geolocalization: true})
  }
  listCart() {
    const productList = [];
    let filterCart = this.props.productosReducers.cardList.filter(
      i => i.idTienda == this.props.route.params.item._id
    );
    filterCart.map(e => {
      this.props.productosReducers.Productos.find(s =>
        s._id == e.idProduct
          ? productList.push({
              id: e.id,
              Nombre: s.Nombre,
              _id: s._id,
              precio: s.precio * e.cantidad,
              cantidad: e.cantidad,
              nota: e.nota,
              descripcion: s.descripcion,
              img: s.img
            })
          : null
      );
    });
    this.setState({ productList });
  }
  async listDelete(id) {
    const { cardListDelete } = this.props;
    await cardListDelete(id);
    this.props.productosReducers.cardList.length <= 0
      ? this.props.navigation.goBack()
      : null;
    this.listCart();
  }
  async createOrder() {
  const { crearOrden, orderById, seeDetailsOrden } = this.props;
  this.setState({ visible: false});
  this.setState({ loadding: true});
    let response = await crearOrden(
      this.state.productList,
      "PENDIENTE",
      this.props.route.params.item._id,
      this.state.region,
      this.props.userReducer.token,
      "",
      this.props.route.params.nameSpace
    );
    if (response.status === 200) {
      await orderById(this.props.userReducer.token)
      await seeDetailsOrden(response.data.ordenes)
      this.props.navigation.goBack();
      this.props.navigation.navigate("Pedidos", { screen: "Pedidos" });
      this.props.navigation.navigate("Pedidos", { screen: "pedidosDetails" });
    } else {
      this.setState({ dialog: true });
    }
    this.setState({ loadding: false });
  }
  render() {
    let total = 0;
    const { navigation } = this.props;
    this.state.productList.map(i => {
      total = total + i.precio;
    });
    console.log(this.props.route.params.item.tipoEnvio)
    return (
      <ScrollView>
        {this.state.productList.map((i, index) => (
          <View key={index}>
            <View opacity={1.0}></View>
            <Surface style={styles.surface}>
              <View style={styles.card}>
                <View style={styles.align}>
                  <View style={styles.image}>
                    <Images
                      resizeMode="cover"
                      url={`https://file.rubengarcia.me/api/image/disystage/${this.props.route.params.nameSpace}/${i.img}?w=150&q=50&p=true`}
                      style={{
                        width: 110,
                        height: 110,
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                      }}
                    />
                  </View>
                  <View style={styles.titles}>
                    <Headline>{i.Nombre}</Headline>
                    <Paragraph>{i.descripcion}</Paragraph>
                    <Paragraph>cantindad: {i.cantidad}</Paragraph>
                  </View>
                </View>
                <View style={styles.borrar}>
                  <IconButton
                    icon={Cerrar}
                    color="red"
                    size={20}
                    onPress={() => this.listDelete(i.id)}
                    />
                    <Paragraph style={{paddingRight:10, paddingBottom: 5}}>${i.precio.toFixed(2)}</Paragraph>
                </View>
              </View>
            </Surface>
          </View>
        ))}
        {this.props.route.params.item.tipoEnvio=="recoleccion,envioDomicilio"?
        <View>
          <Button
            color="white"
            onPress={e =>this.setState({tipoEnvio: 1})}
          >
            <View style={this.state.tipoEnvio==1?styles.direccion: styles.direccionDisable}>
              <Image
                source={carroCompra}
                style={{ width: null, resizeMode: "cover", height: 60, width: 60 }}
                />
              <View style={{ alignItems: "flex-start" }}>
                <Title>Recogerlo por mi mismo</Title>
              </View>
            </View>
          </Button>
          <Button
            color="white"
            onPress={e =>this.setState({tipoEnvio: 2})}
          >
            <View style={this.state.tipoEnvio==2?styles.direccion: styles.direccionDisable}>
              <Image
                source={Mapa}
                style={{ width: null, resizeMode: "cover", height: 60, width: 60 }}
              />
              <View style={{ alignItems: "flex-start" }}>
                <Title>Enviar a mi direccion guardada</Title>
                <Button
                  mode="text"
                  color="red"
                  onPress={e =>
                    navigation.navigate("EditDirection", {
                      screen: "EditDirection"
                    })
                  }
                >
                  Editar
                </Button>
              </View>
            </View>
          </Button>
        </View>
        :null}
        {this.props.route.params.item.tipoEnvio=="recoleccion"?
        <View>
          <Button
            color="white"
            onPress={e =>this.setState({tipoEnvio: 1})}
          >
            <View style={this.state.tipoEnvio==1?styles.direccion: styles.direccionDisable}>
              <Image
                source={carroCompra}
                style={{ width: null, resizeMode: "cover", height: 60, width: 60 }}
                />
              <View style={{ alignItems: "flex-start" }}>
                <Title>Recogerlo por mi mismo</Title>
              </View>
            </View>
          </Button>
        </View>
        :null}
        {this.state.tipoEnvio==2?
          <View style={styles.envio}>
            <Title>Envio: Gratis</Title>
          </View>
        :null}
        <View style={styles.total}>
          <Title>Total: ${total.toFixed(2)}</Title>
        </View>
        <View style={styles.centrar}>
          <View style={{padding:20}}></View>
          <Button
            loading={this.state.loadding||!this.state.geolocalization}
            disabled={this.state.loadding||!this.state.geolocalization}
            theme={{
              colors: { primary: "red", underlineColor: "transparent" }
            }}
            mode="contained"
            onPress={() => this.setState({ visible: true })}
          >
            Iniciar pedido
          </Button>
        </View>
        <View>
          <Dialog.Container visible={this.state.visible}>
            <Dialog.Title>Todo listo?</Dialog.Title>
            <Dialog.Description>
              Una vez terminada la orden de compra no se podrá modificar.
            </Dialog.Description>
            <Dialog.Button
              label="Cancel"
              onPress={() => this.setState({ visible: false })}
            />
            <Dialog.Button label="Inciar" onPress={async() => !this.state.loadding?this.createOrder():null} />
          </Dialog.Container>
        </View>
        <View>
          <Dialog.Container visible={this.state.dialog}>
            <Dialog.Title>Algo salio mal</Dialog.Title>
            <Dialog.Description>Es posible que no este dentro de los limites de la localidad, favor de intentar mas tarde.</Dialog.Description>
            <Dialog.Button
              label="Cerrar"
              onPress={() => this.setState({ dialog: false })}
            />
          </Dialog.Container>
        </View>
        <View style={{padding:10}}></View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  direccion: {
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    backgroundColor: "white",
    borderRadius: 10,
    marginTop: 20,
    marginLeft: 40,
    marginRight: 40,
    height:85
  },
  direccionDisable: {
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    opacity:100,
    borderRadius: 10,
    marginTop: 20,
    marginLeft: 40,
    marginRight: 40,
    height:85
  },
  envio: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: 20,
    paddingRight: 20
  },
  total: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: 20
  },
  centrar: {
    display: "flex",
    alignItems: "center"
  },
  button: {
    backgroundColor: "red",
    margin: 20,
    width: 200,
    height: 50,
    justifyContent: "center"
  },
  borrar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  surface: {
    borderRadius: 10,
    marginTop: 18,
    marginLeft: 18,
    marginRight: 18
  },
  align: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  titles: {
    marginLeft: 10,
    marginRight: 10,
    justifyContent: "flex-start"
  },
  image: {
    borderRadius: 22,
    justifyContent: "center"
  }
});

const mapStateToProps = ({ productosReducers, userReducer,ordenReducers }) => {
  return {
    ordenReducers,
    productosReducers,
    userReducer
  };
};

const mapDispatchToProps = {
  cardListDelete,
  crearOrden,
  orderById,
  seeDetailsOrden
};

export default connect(mapStateToProps, mapDispatchToProps)(cart);
