import * as React from 'react';
import { Button, Text, View, SafeAreaView } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import Ordenes from '../modules/Ordenes'
import Title from '../components/Title'
import PedidosDetails from '../modules/PedidosDetails'

function OrdenesScreen({ navigation }) {
  return (
    <View style={{ flex: 1 }}>
      <Title />
      <Ordenes navigation={navigation} />
    </View>
  );
} 

function pedidoDetailScreen({ navigation, route }) {
  return (
    <>
    <Title options={true} navigation={navigation} routerBack="Pedidos" routerBackScreen="Pedidos"/>
    <View style={{ flex: 1 }}>
      <PedidosDetails navigation={navigation} route={route}/>
    </View>
    </>
  );
}

const HomeStack = createStackNavigator();

export default function OrdenesStackScreen(props) {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen name="Ordenes" component={OrdenesScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerTintColor: '#fff',
      }} />
      <HomeStack.Screen name="OrdenesDetails" component={pedidoDetailScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerTintColor: '#fff',
      }} />
      </HomeStack.Navigator>
  );
}