import axios from 'axios'
import { TRAER_TODOS, ERROR } from '../types/tiendas.types'

export const traerTodos = () => async (dispatch) => {
  try {
    const locales = await axios.get('https://api.disy.com.mx/api/getalltiendas')
    dispatch({
      type: TRAER_TODOS,
      payload: {locales: locales.data}
    })
    dispatch({
			type: ERROR,
			payload: ''
		})
  } catch {
    dispatch({
			type: ERROR,
			payload: 'Algo salió mal, intente más tarde.'
		})
  }
}

export const tiendaById = (idTienda, token) => async (dispatch) => {
  try {
    const tienda = await axios.post('https://api.disy.com.mx/api/tiendaById',
    {idTienda},
    {headers: {
      authorization: token
    }}
    )
    return tienda.data
  } catch {
    dispatch({
			type: ERROR,
			payload: 'Algo salió mal, intente más tarde.'
		})
  }
}