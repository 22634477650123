import React, { Component } from "react";
import {
  Image,
  View,
  ScrollView,
  RefreshControl,
  StyleSheet
} from "react-native";
import Images from "../../components/Images"
import {cardListAdd} from '../../actions/productos.actions'
import { Title, Paragraph, TextInput, IconButton, Button } from "react-native-paper";
import { connect } from 'react-redux'

export class productosDetailsHome extends Component {
  state = {
    nota: "",
    productCount: 1,
    visible: false,
    idTienda: this.props.route.params.item.idTienda,
    _id: this.props.route.params.item._id,
  };
  componentDidMount() {
    // this.setState({})
  }
  productAdd = () => {
    this.setState({visible: true})
    var id=1
    this.props.productosReducers.cardList.map(i=>{
      console.log(i)
      if(id<=i.id){
        id=i.id+1
      } 
    })
    console.log(id)
    let {cardListAdd} = this.props
    cardListAdd({id,options: this.state})
    this.setState({visible: false})
    this.props.navigation.goBack()
  }
  render() {
    // console.log(`https://file.rubengarcia.me/api/image/disystage/${this.props.route.params.nameSpace}/${this.props.route.params.item.img}?w=500&q=40`)
    return (
      <>
        <ScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={<RefreshControl refreshing={false} />}
        >
          <View>
            <Images
              resizeMode="cover"
              style={{ width: null, resizeMode: "cover", height: 320 }}
              url={`https://file.rubengarcia.me/api/image/disystage/${this.props.route.params.nameSpace}/${this.props.route.params.item.img}?w=500&q=40`}
            />
          </View>
          <View style={styles.content}>
            <Title style={styles.title}>
              {this.props.route.params.item.Nombre}
            </Title>
            <Paragraph>{this.props.route.params.item.descripcion}</Paragraph>
          </View>
          <View style={styles.InputEmail}>
            <TextInput
              mode="outlined"
              theme={{
                colors: { primary: "red", underlineColor: "transparent" }
              }}
              label="Detalles del producto?"
              style={styles.InputEmail}
              value={this.state.nota}
              onChangeText={nota => this.setState({ nota })}
              selectionColor="red"
              underlineColor="red"
            />
          </View>
          <View style={styles.row}>
            <IconButton
              icon="minus-circle-outline"
              color={this.state.productCount > 1 ? "red" : "gray"}
              size={55}
              onPress={() =>
                this.setState({
                  productCount:
                    this.state.productCount > 1
                      ? this.state.productCount - 1
                      : 1
                })
              }
            />
            <Title style={styles.productCount}>{this.state.productCount}</Title>
            <IconButton
              icon="plus-circle-outline"
              color={"red"}
              size={55}
              onPress={() =>
                this.setState({ productCount: this.state.productCount + 1 })
              }
            />
          </View>
          <View style={styles.alignItems}>
            <Button
              icon="cart"
              theme={{ colors: { primary: 'red',underlineColor:'transparent',}}}  
              mode="contained"
              onPress={() => this.productAdd()}
              loading={this.state.visible}
              disabled={this.state.visible}
            >
              Agregar
            </Button>
          </View>
        </ScrollView>
      </>
    );
  }
}
const styles = StyleSheet.create({
  content: {
    display: "flex",
    alignItems: "center"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  InputEmail: {
    padding: 15
  },
  title: {
    paddingTop: 10,
    fontSize: 30,
    fontWeight: "600"
  },
  productCount: {
    fontSize: 30,
    fontWeight: "600"
  },
  alignItems: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cart: {
    height: 55,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 250
  }
});
const mapStateToProps = ({productosReducers, userReducer}) => {
  return {
    productosReducers,
    userReducer
  };
};


const mapDispatchToProps = {
  cardListAdd,
}
export default connect(mapStateToProps, mapDispatchToProps)(productosDetailsHome);
