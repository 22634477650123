import React from "react";
import { Avatar } from "react-native-paper";
import {
  StyleSheet,
  Image,
  View,
  Dimensions,
  TouchableHighlight,
  Text
} from "react-native";
import { connect } from "react-redux";
import logo from "../../../assets/logo1.png";
import backLeft from "../../../assets/backLeft.png";
class title extends React.Component {
  render() {
    const { navigation } = this.props;
    return (
      <View style={styles.search}>
        <View style={styles.bottom}>
          {!this.props.options ? (
            <Image source={logo} style={styles.searchIcon} />
          ) : (
            <TouchableHighlight
              underlayColor={"red"}
              onPress={() =>
                navigation.navigate(this.props.routerBack, {
                  screen: this.props.routerBackScreen
                })
              }
            >
              <Image source={backLeft} style={styles.backIcon} />
            </TouchableHighlight>
          )}
        </View>
        <View style={styles.bottom}></View>
        <View style={styles.bottom}>
        {this.props.userReducer.avatar.length<=2?
          <View style={styles.AvatarIcon}>
            <Text style={{fontSize: 22}}>{this.props.userReducer.avatar}</Text>
          </View>:
          <Image source={{uri: `https://file.rubengarcia.me/api/image/disystage/profilePicture/${this.props.userReducer.avatar}?w=300&q=20`}} style={{ width: 38, height: 38,borderRadius: 90 }} />
          }
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  backIcon: {
    width: 25,
    height: 25,
    tintColor: "white"
  },
  searchIcon: {
    width: 65,
    height: 65
  },
  bottom: {
    height: 85,
    backgroundColor: "red",
    justifyContent: "center",
    paddingTop: 30,
    paddingHorizontal: 10,
    borderRadius: 30
  },
  search: {
    justifyContent: "space-between",
    width: Dimensions.get("window").width,
    flexDirection: "row",
    backgroundColor: "red"
  },
  AvatarIcon: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    margin: 15,
    width: 40,
    height: 40,
    borderRadius: 90,
    backgroundColor:
      "rgb(" +
      Math.floor(Math.random() * 256) +
      "," +
      Math.floor(Math.random() * 256) +
      "," +
      Math.floor(Math.random() * 256) +
      ")"
  },
  textIcon: {
    margin: 10,
    padding: 10,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: "#fff"
  }
});

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps, {})(title);