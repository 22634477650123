import {ERROR_ORDEN,TRAER_POR_ID_ORDEN,ADD_ORDEN,SEE_DETAILS_ORDEN, CLEAR_ORDENES} from '../types/orden.types';

const INITIAL_STATE = {
	ordenes: [],
	item: {},
	error: ''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TRAER_POR_ID_ORDEN:
			return { ...state, ordenes: action.payload, cargando: false, error: '' };
		case ERROR_ORDEN:
			return { ...state, error: action.payload, cargando: false }
		case ADD_ORDEN:
			return { ...state, ordenes: [action.payload, ...state.ordenes.filter(i=> i._id !== action.payload._id)]}
		case SEE_DETAILS_ORDEN:
			return { ...state, item: action.payload}
		case CLEAR_ORDENES:
			return {...state, ordenes: [], item: {}, error: ''}
		default: return state;
	};
};