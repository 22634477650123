import * as React from 'react';
import { Button, Text, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import Setting from '../modules/Setting'
import Title from '../components/Title'
import EditDirection from '../modules/EditDirection'

function settingDetailScreen({navigation}) {
  return (
    <>
    <Title options={true} navigation={navigation} routerBack="ajustes" routerBackScreen="ajustes"/>
    <View style={{ flex: 1 }}>
      <EditDirection navigation={navigation} ruta={'ajustes'}/>
    </View>
    </>
  );
}

function settingScreen({ navigation }) {
  return (
    <>
    <Title navigation={navigation} />
    <View style={{ flex: 1 }}>
      <Setting navigation={navigation}/>
    </View>
    </>
  );
}

const HomeStack = createStackNavigator();

export default function settingStackScreen({navigation}) {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen name="ajustes" component={settingScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerBackTitleVisible: false,
      }}/>
      <HomeStack.Screen name="ajustesDetailsMap" component={settingDetailScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerBackTitleVisible: false,
      }} />
    </HomeStack.Navigator>
  );
}