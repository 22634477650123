import React, { useState, useEffect } from "react";
import { View, StyleSheet,Platform, Button, Image } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { ProgressBar } from "react-native-paper";
import axios from "axios";
export const UploadImage = ({getUrlImage, image, seeImage, nameSpace, token, title = "Agregar Imagen"}) => {
  const [selectedFiles, setSelectedFiles] = useState("");
  const [progress, setProgress] = useState(0);
  const [loadding, setLoadding] = useState(false);
  useEffect(() => {
    setSelectedFiles(image)
  })

  const pickImage = async () => {
    let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync();
    if (permissionResult.granted === false) {
      alert('Permiso requerido para utilizar la camara');
      return;
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      allowsEditing: true,
      aspect: [16, 9]
    });
    if(result.cancelled) {
      setLoadding(false)
      return 0
    }
    setLoadding(true)
    let localUri = result.uri;
    let filename = localUri.split("/").pop();
    let match = /\.(\w+)$/.exec(filename);
    let type = match ? `image/${match[1]}` : `image`;
    let formData = new FormData();
    console.log(localUri)
    formData.append("userFile", {
      uri: localUri,
      name: filename,
      filename,
      type
    });
    let progress = 0;
    console.log(1)
    const resp = await axios.post(
    `https://file.rubengarcia.me/api/disystage/${nameSpace}/photo`,
    formData,
    {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: token
    },
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
        );
        progress = percentCompleted;
        setProgress(progress);
      }
    }
    );
    setSelectedFiles(localUri)
    setLoadding(false)
    getUrlImage(resp.data.ruta);
  };
  const handleImage = async event => {
    setLoadding(true)
    let progress = 0;
    if(!event.target.files) {
      return null
    }
    let formData = new FormData();
    formData.append("userFile", event.target.files[0]);
    const resp = await axios.post(
      `https://file.rubengarcia.me/api/disystage/${nameSpace}/photo`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          'authorization': token
        },
        onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
          );
          progress = percentCompleted;
          setProgress(progress);
        }
      }
    );
    // setSelectedFiles(localUri)
    setLoadding(false)
    getUrlImage(resp.data.ruta);
  };
  return (
    <View style={styles.progress}>
      {loadding ? (
        <View style={{paddingVertical: 15}}>
          <ProgressBar
            progress={progress / 100}
            theme={{
              colors: { primary: "red", underlineColor: "transparent" }
            }}
          />
        </View>
      ) : null}
      {selectedFiles!=""&&seeImage?
        <Image style={{ width: 200, height: 200 }} source={{uri: selectedFiles}}/>
      :null}
      {Platform.OS=="web"?
      <input type="file" placeholder="imagen" onChange={e => handleImage(e)}/>
      :<Button title={title} onPress={pickImage} />}
    </View>
  );
};
const styles = StyleSheet.create({
  progress: {
    paddingTop: 5,
    paddingBottom: 5,
  }
});
export default UploadImage;
