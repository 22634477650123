import axios from 'axios'
import { ERROR, TRAER_POR_ID_TIENDA_PRODUCTOS, LIMPIAR_PRODUCTOS,TRAER_POR_ID_TIENDA_PRODUCTOS_HEADER, CARD_LIST_ADD, CARD_LIST_DELETE} from '../types/productos.types'

export const traerPorIdTienda = (id) => async (dispatch) => {
  try {
    const productos = await axios.get(`https://api.disy.com.mx/api/productosPorIdTienda/${id}`)
    dispatch({
      type: TRAER_POR_ID_TIENDA_PRODUCTOS,
      payload: productos.data.todos
    })
    dispatch({
      type: TRAER_POR_ID_TIENDA_PRODUCTOS_HEADER,
      payload: ["Todos",...productos.data.header]
    })
    dispatch({
			type: ERROR,
			payload: ''
		})
  } catch {
    dispatch({
			type: ERROR,
			payload: 'Algo salió mal, intente más tarde.'
		})
  }
}
export const limpiarProduct = () => (dispatch) => {
  dispatch({
    type: LIMPIAR_PRODUCTOS
  })
}
export const cardListAdd = (product) => (dispatch) => {
  dispatch({
    type: CARD_LIST_ADD,
    payload: {
      id: product.id,
      idTienda: product.options.idTienda,
      idProduct: product.options._id,
      cantidad: product.options.productCount,
      nota: product.options.nota,
    }
  })
}
export const cardListDelete = (id) => (dispatch) => {
  dispatch({
    type: CARD_LIST_DELETE,
    payload: id
  })
}
export const listByIdStore = (authorization) => async (dispatch) => {
  try {
    const productos = await axios.get(`https://api.disy.com.mx/api/productosPorIdTienda`,{ headers: {authorization}})
    dispatch({
      type: TRAER_POR_ID_TIENDA_PRODUCTOS,
      payload: productos.data.todos
    })
    dispatch({
      type: TRAER_POR_ID_TIENDA_PRODUCTOS_HEADER,
      payload: []
    })
    dispatch({
			type: ERROR,
			payload: ''
		})
  } catch {
    dispatch({
			type: ERROR,
			payload: 'Algo salió mal, intente más tarde.'
		})
  }
}
export const addProduct = (authorization, product) => async (dispatch) => {
  try {
    const producto = await axios.post(`https://api.disy.com.mx/api/postProductos`,product, {
      headers: {
        authorization
      },
    })
    if (producto.data.status=='ok'){
      dispatch({
        type: ERROR,
        payload: ''
      })
      return true
    } else {
      dispatch({
        type: ERROR,
        payload: 'Algo salió mal, intente más tarde.'
      })
      return false
    }
  } catch {
    dispatch({
			type: ERROR,
			payload: 'Algo salió mal, intente más tarde.'
    })
    return false
  }
}
export const editProduct = (authorization, product) => async (dispatch) => {
  try {
    const producto = await axios.post(`https://api.disy.com.mx/api/UpdateProductos`,product, {
      headers: {
        authorization
      },
    })
    if (producto.data.status=='ok'){
      dispatch({
        type: ERROR,
        payload: ''
      })
      return true
    } else {
      dispatch({
        type: ERROR,
        payload: 'Algo salió mal, intente más tarde.'
      })
      return false
    }
  } catch {
    dispatch({
			type: ERROR,
			payload: 'Algo salió mal, intente más tarde.'
    })
    return false
  }
}
export const UpdateProductoStatusByCategory = (authorization, disponible, categoria) => async (dispatch) => {
  try {
    const producto = await axios.post(`https://api.disy.com.mx/api/UpdateProductoStatusByCategory`, {
      disponible,
      categoria
    },{
      headers: {
        authorization
      },
      data: {
        disponible,
        categoria
      }
    })

    if (producto.data.status=='ok'){
      dispatch({
        type: ERROR,
        payload: ''
      })
      return true
    } else {
      dispatch({
        type: ERROR,
        payload: 'Algo salió mal, intente más tarde.'
      })
      return false
    }
  } catch (error) {
    
  }
}