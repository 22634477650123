import * as React from 'react';
import { Button, Text, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import Pedidos from '../modules/Pedidos'
import PedidosDetails from '../modules/PedidosDetails'
import PedidosMap from '../modules/PedidosMap'
import Title from '../components/Title'

function pedidoScreen({navigation}) {
  return (
    <>
    <View style={{ flex: 1}}>
      <Title />
      <Pedidos navigation={navigation}/>
    </View>
    </>
  );
}

function pedidoDetailScreen({ navigation, route }) {
  return (
    <>
    <Title options={true} navigation={navigation} routerBack="Pedidos" routerBackScreen="Pedidos"/>
    <View style={{ flex: 1 }}>
      <PedidosDetails navigation={navigation} route={route}/>
    </View>
    </>
  );
}

function pedidoMapScreen({ navigation, route }) {
  return (
    <>
    <Title options={true} navigation={navigation} routerBack="pedidosDetails" routerBackScreen="pedidosDetails"/>
    <View style={{ flex: 1 }}>
      <PedidosMap navigation={navigation} route={route}/>
    </View>
    </>
  );
}
const HomeStack = createStackNavigator();

export default function pedidoStackScreen({navigation}) {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen name="Pedidos" component={pedidoScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerBackTitleVisible: false,
      }} />
      <HomeStack.Screen name="pedidosDetails" component={pedidoDetailScreen} options={{ title: 'Market' }} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerBackTitleVisible: false,
      }} />
      <HomeStack.Screen name="pedidosMap" component={pedidoMapScreen} options={{ title: 'Market' }} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerBackTitleVisible: false,
      }} />
    </HomeStack.Navigator>
  );
}