import React, { Component } from "react";
import { ScrollView, RefreshControl, StyleSheet, View, Text } from "react-native";
import { connect } from "react-redux";
import { Card, Title, Paragraph } from "react-native-paper";
import { traerTodos } from "../../actions/tiendas.actions";
import {addOrden} from '../../actions/orden.actions'
import Promociones from '../../components/Promociones'
export class Home extends Component {
  state = {
    refreshing: false,
    socketState: false
  };
  async componentDidMount() {
    const { traerTodos, addOrden } = this.props;
    await traerTodos();

    // var socket = io.connect('https://api.disy.com.mx:4000', {
    //   query: {
    //     token: this.props.userReducer.token
    //   }
    // });
    // socket.on('connect', () => this.setState(socket.connected ? {socketState:true} : {socketState:false}));
    // socket.on('disconnect', () => this.setState(socket.connected ? {socketState:true} : {socketState:false}));
    // socket.on(this.props.userReducer.email, (date) => {
    //   const finddate = this.props.ordenReducers.ordenes.find(element => date._id === element._id)
    //   if (finddate) {
    //     addOrden([date, ...this.props.ordenReducers.ordenes.filter(i=> i._id !== date._id)].sort((a, b) => a.nomOrden - b.nomOrden).reverse())
    //   } else {
    //     addOrden([date, ...this.props.ordenReducers.ordenes].sort((a, b) => a.nomOrden - b.nomOrden).reverse())
    //   }
    // });
  }
  onRefresh = async () => {
    const { traerTodos } = this.props;
    this.setState({ refreshing: true });
    await traerTodos();
    this.setState({ refreshing: false });
  };
  render() {
    const { navigation } = this.props;
    return (
    <>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={
          <RefreshControl
            refreshing={this.state.refreshing}
            onRefresh={this.onRefresh}
          />
        }
        >
        {/* <Promociones/> */}
        {this.props.tiendasReducers.Tiendas
          ? this.props.tiendasReducers.Tiendas.map((item, i) => {
              return (
                <Card
                  key={i}
                  style={styles.card}
                  navigation={navigation}
                  onPress={() =>
                    navigation.navigate("DetailsHome", {
                      screen: "DetailsHome",
                      item
                    })
                  }
                >
                  <Card.Cover
                    source={{ uri: `https://file.rubengarcia.me/api/image/disystage/${item.nameSpace}/${item.imgLogo}?w=500&q=40&p=true`}}
                    style={{ margin: 0 }}
                  />
                  <Card.Content>
                    <Title>{item.Nombre}</Title>
                    <Paragraph>{item.descripcion}</Paragraph>
                    <Paragraph>{item.tipoLocal}</Paragraph>
                  </Card.Content>
                </Card>
              );
            })
          : null}
      </ScrollView>
      </>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center"
  },
  cardInit: {
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    justifyContent: "space-between"
  },
  cardList: {
    width: 150,
    marginTop: 20
  },
  card: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20
  },
  list: {
    marginLeft: 20,
    marginRight: 20
  },
  bottom: {
    backgroundColor: "red",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  search: {
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  searchIcon: {
    width: 29,
    tintColor: "white",
    height: 29,
    alignContent: "flex-end"
  },
  AvatarIcon: {
    backgroundColor: "red"
  },
  leftContent: {
    flexDirection: "row",
    alignItems: "center"
  }
});
const mapStateToProps = ({ tiendasReducers, userReducer, ordenReducers }) => {
  return {
    tiendasReducers,
    ordenReducers,
    userReducer,
  };
};

const mapDispatchToProps = {
  traerTodos,
  addOrden,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
