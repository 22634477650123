import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import {Title, Text, TextInput, Button} from 'react-native-paper'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { connect } from 'react-redux'
import {recordarContrasena} from '../../actions/user.actions'
class RecordarCon extends Component {
  state= {
    text:'',
    visible: false
  }
  enviarCorreo = async () => {
    this.setState({visible: true})
    const {navigation} = this.props
    const {recordarContrasena} = this.props
    await recordarContrasena(this.state.text)
    navigation.navigate('Login', { screen: 'Login'})
    this.setState({visible: false})
  }
  render() {
    return (
      <KeyboardAwareScrollView
      resetScrollToCoords={{ x: 0, y: 0 }}
      contentContainerStyle={styles.container}
      scrollEnabled={false}
      >
      <View styles={styles.row}>
        <View><Text></Text></View>
        <View>
          <Title style={styles.title} theme={{ colors: { primary: 'red',underlineColor:'transparent',}}}>Restablezca su contraseña.</Title>
          <Text style={styles.subtitle}>le enviaremos un correo electronico con mas instrucciones sobre como restablecer su contraseña</Text>
          <TextInput
            label='Correo electronico'
            mode='outlined'
            style={styles.correo}
            value={this.state.text}
            onChangeText={text => this.setState({ text })}
            theme={{ colors: { primary: 'red',underlineColor:'transparent',}}}
          />
          <View style={styles.Enviar}>
            <Button mode="contained" theme={{ colors: { primary: 'red',underlineColor:'transparent',}}} onPress={this.enviarCorreo}loading={this.state.visible} disabled={this.state.visible}>{this.state.visible? 'Enviando': 'Enviar Email'}</Button>
          </View>
        </View>
      </View>
      </KeyboardAwareScrollView>
    )
  }
}

const styles = StyleSheet.create({
  title:{
    paddingTop:80,
    paddingLeft: 20,
    paddingRight: 20
  },
  subtitle: {
    padding:25
  },
  correo: {
    paddingLeft: 20,
    paddingRight: 20
  },
  Enviar: {
    justifyContent:'center',
    padding: 20,
  },
  row: {
    paddingTop: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    overlayColor:'red'
  },
})

const mapStateToProps = ({userReducer}) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  recordarContrasena,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordarCon) 