import React, { Component } from "react";
import { View, TouchableOpacity, StyleSheet, Image, ScrollView, RefreshControl } from "react-native";
import { connect } from "react-redux";
import { Surface, Headline, Paragraph, Button, Switch } from "react-native-paper";
import { listByIdStore, UpdateProductoStatusByCategory } from "../../actions/productos.actions";
import {addOrden} from '../../actions/orden.actions'
import io from "socket.io-client";

export class Productos extends Component {
  state={
    refreshing: false,
    socketState: false

  }
  async componentDidMount() {
    const { listByIdStore, userReducer, addOrden } = this.props;
    // var socket = io.connect('https://api.disy.com.mx:4000', {
    //   query: {
    //     token: this.props.userReducer.token
    //   }
    // });
    // socket.on('connect', () => this.setState(socket.connected ? {socketState:true} : {socketState:false}));
    // socket.on('disconnect', () => this.setState(socket.connected ? {socketState:true} : {socketState:false}));
    // socket.on(this.props.userReducer.email, (date) => {
    //   const finddate = this.props.ordenReducers.ordenes.find(element => date._id === element._id)
    //   if (finddate) {
    //     addOrden([date, ...this.props.ordenReducers.ordenes.filter(i=> i._id !== date._id)].sort((a, b) => a.nomOrden - b.nomOrden).reverse())
    //   } else {
    //     addOrden([date, ...this.props.ordenReducers.ordenes].sort((a, b) => a.nomOrden - b.nomOrden).reverse())
    //   }
    // });
    await listByIdStore(userReducer.token);
  }
  onRefresh = async () => {
    const { listByIdStore, userReducer } = this.props;
    await listByIdStore(userReducer.token);
  }
  handleUpdateSwitch = async (categoria, disponibles) => {
    const {UpdateProductoStatusByCategory, listByIdStore, userReducer} = this.props
    await UpdateProductoStatusByCategory(this.props.userReducer.token, !disponibles, categoria)
    await listByIdStore(userReducer.token);
  }
  render() {
    const { productosReducers, navigation } = this.props;
    return (
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={
          <RefreshControl
            refreshing={this.state.refreshing}
            onRefresh={this.onRefresh}
          />
        }
      >
        {/* <Title>{this.state.socketState?"conectado":"desconectado"}</Title> */}
        <View>
          <View style={styles.addProduct}>
            <Button
              icon="book-plus-multiple-outline"
              theme={{
                colors: { primary: "red", underlineColor: "transparent" }
              }}
              mode="contained"
              onPress={() =>
                navigation.navigate("ProductosAdd", {
                  screen: "ProductosAdd",

                })
              }
            >
              Agregar
            </Button>
          </View>
          {productosReducers.Productos ?productosReducers.Productos.map((item, i) => (
            <>
            <View style={styles.SwitchCom}>
              <Headline style={styles.tituloCat}>{item.header}</Headline>
              <Switch value={item.disponible} onValueChange={()=>this.handleUpdateSwitch(item.header, item.disponible)} color="red"/>
            </View>
            {item.data.map(item2=>
            <View key={i}>
              <View opacity={1.0}></View>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("ProductosEdit", {
                    screen: "ProductosEdit",
                    item2
                  })
                }
                style={styles.button}
              >
                <Surface style={styles.surface}>
                  <View key={i} data-key={i} style={styles.card}>
                    <View style={styles.image}>
                      <Image
                        source={{ uri: `https://file.rubengarcia.me/api/image/disystage/${this.props.userReducer.nameSpace}/${item2.img}?w=100&q=40` }}
                        style={{
                          width: 80,
                          height: 80,
                          borderRadius: 10
                        }}
                      />
                    </View>
                    <View style={styles.titles}>
                      <Headline>{item2.Nombre}</Headline>
                      <Paragraph>{item2.descripcion}</Paragraph>
                      <Paragraph>${item2.precio}</Paragraph>
                    </View>
                  </View>
                </Surface>
              </TouchableOpacity>
            </View>
            )}
            </>
          )):null}
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  SwitchCom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "row",
    marginRight: 18,
  },
  tituloCat: {
    paddingTop: 10,
    marginLeft: 18,
  },
  addProduct: {
    paddingTop: 10,
    paddingEnd: 15,
    display: "flex",
    alignItems: "flex-end"
  },
  surfaceheader: {
    borderBottomEndRadius: 30,
    borderBottomStartRadius: 30,
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    padding: 18
  },
  surface: {
    borderRadius: 10,
    marginTop: 18,
    marginLeft: 18,
    marginRight: 18
  },
  fab: {
    shadowOpacity: 22,
    borderRadius: 30,
    backgroundColor: "red",
    position: "absolute",
    bottom: 15,
    zIndex: 1,
    right: 10
  },
  fabs: {
    shadowOpacity: 22,
    borderRadius: 30,
    color: "white",
    position: "absolute",
    bottom: 50,
    right: 22,
    zIndex: 2
  },
  cancelar: {
    color: "white",
    backgroundColor: "#FFF6F4"
  },
  ordenar: {
    color: "white",
    backgroundColor: "red"
  },
  header: {},
  textRegister: {
    flexDirection: "row",
    textAlign: "center",
    color: "gray"
  },
  Lista_de_pedidos: {
    flexDirection: "row",
    textAlign: "center",
    color: "gray",
    paddingTop: 22
  },
  card: {
    flexDirection: "row",
    margin: 10,
    marginLeft: 10,
    marginRight: 10
  },
  titles: {
    marginLeft: 10,
    marginRight: 10,
    justifyContent: "flex-start"
  },
  image: {
    borderRadius: 22,
    justifyContent: "flex-end"
  },
  lineStyle: {
    backgroundColor: "#A2A2A2",
    height: 2,
    width: 165
  }
});
const mapStateToProps = ({ productosReducers, userReducer, ordenReducers }) => ({
  productosReducers,
  userReducer,
  ordenReducers
});

const mapDispatchToProps = {
  listByIdStore,
  addOrden,
  UpdateProductoStatusByCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(Productos);
