/* eslint-disable */
import {ERROR_ORDEN,TRAER_POR_ID_ORDEN, ADD_ORDEN, SEE_DETAILS_ORDEN} from '../types/orden.types'
import {LIMPIAR_CART} from '../types/productos.types'
import axios from 'axios';

export const crearOrden = (productos,statusventa,idTienda,geo,authorization,recomendaciones,nameSpace) => async (dispatch) => {
  let total;
  productos.map(i=>total=+i.precio)
  let data = { productos,statusventa,idTienda,geo,authorization,recomendaciones, total,nameSpace }
  try {
    let response = await axios.post(
      'https://api.disy.com.mx/api/CrearOrden',
      data,
      {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authorization
      },
    });
    dispatch({
      type: ERROR_ORDEN,
      payload: '',
    });
    dispatch({
      type: LIMPIAR_CART,
      payload: idTienda,
    });
    return response
  } catch (error) {
    dispatch({
      type: ERROR_ORDEN,
      payload: error,
    });
    console.log(error)
    return 404
  }
}
export const orderById = (authorization) => async (dispatch) => {
  try {
    let productos = await axios.get(`https://api.disy.com.mx/api/getordenbyuser`,
    {headers: {
      'Content-Type': 'application/json',
      'authorization': authorization
    }})
    dispatch({
      type: TRAER_POR_ID_ORDEN,
      payload: productos.data.todos
    })
    dispatch({
      type: ERROR_ORDEN,
      payload: '',
    });
  } catch {
    dispatch({
			type: ERROR_ORDEN,
			payload: 'Algo salió mal, intente más tarde.'
		})
  }
}
export const addOrden = (orden) => (dispatch) => {
  dispatch({
    type: ADD_ORDEN,
    payload: orden
  })
}
export const OrdenByIdTienda = (authorization) => async (dispatch) => {
  try {
    const productos = await axios.get(`https://api.disy.com.mx/api/getOrdenByIdTienda`,
    {headers: {
      'Content-Type': 'application/json',
      'authorization': authorization
    }})
    dispatch({
      type: TRAER_POR_ID_ORDEN,
      payload: productos.data.todos
    })
    dispatch({
      type: ERROR_ORDEN,
      payload: '',
    });
  } catch {
    dispatch({
			type: ERROR_ORDEN,
			payload: 'Algo salió mal, intente más tarde.'
		})
  }
} 
export const editEstatusOrden = (authorization, data) => async (dispatch) => {
  let {statusventa, idOrden} = data;
  try {
    let productos = await axios.post(`https://api.disy.com.mx/api/postEditEstatusOrden`,{
      idOrden,
      statusventa
    },
    {headers: {
      'Content-Type': 'application/json',
      'authorization': authorization
    }})
    dispatch({
      type: ERROR_ORDEN,
      payload: '',
    });
    return productos.data
  } catch {
    dispatch({
			type: ERROR_ORDEN,
			payload: 'Algo salió mal, intente más tarde.'
    })
    return 'CANCELADA'
  }
}
export const seeDetailsOrden = (item) => async (dispatch) => {
  dispatch({
    type: SEE_DETAILS_ORDEN,
    payload: item
  })
}
export const userByOrden = (idOrden, token) => async (dispatch) => {
  try {
    let response = await axios.post("https://api.disy.com.mx/api/userByOrden", {idOrden}, {
      headers:{
        authorization: token
      }
    })
    return response.data
  } catch (error) {
    
  }
}