import React, { Component } from "react";
import { Text, View, StyleSheet, Image } from "react-native";
import { Button, Avatar } from 'react-native-paper';
import { connect } from "react-redux";
import {limpiarProduct} from '../../actions/productos.actions'
import { logout, updateAvarar, updateLogo, updatePortada } from "../../actions/user.actions";
import UploadImage from '../../components/UploadImage'
import camara from '../../../assets/camara.png'
export class setting extends Component {
  state={
    imageServe:""
  }
  getUrlImage = async imageServe => {
    const {updateAvarar} = this.props
    await updateAvarar(imageServe, this.props.userReducer.token)
    this.setState({ imageServe, visibleImage: true });
  };
  getUrlImageLogo = async imageServe => {
    const {updateLogo, userReducer} = this.props
    await updateLogo(imageServe, this.props.userReducer.token, userReducer.nameSpace)
    // this.setState({ imageServe, visibleImage: true });
  };
  getUrlImagePortada = async imageServe => {
    const {updatePortada, userReducer} = this.props
    await updatePortada(imageServe, this.props.userReducer.token, userReducer.nameSpace)
    // this.setState({ imageServe, visibleImage: true });
  };
  handleLogout = async () => {
    const {logout, limpiarProduct} = this.props
    await limpiarProduct();
    await logout();
  }
  render() {
    const {navigation} = this.props
    return (
      <View style={styles.container}>
        <View style={styles.avatar}>
          {this.props.userReducer.avatar.length<=2?
          <View style={styles.AvatarIcon}>
            <Text style={{fontSize: 45}}>{this.props.userReducer.avatar}</Text>
          </View>:
          <Image source={{uri: `https://file.rubengarcia.me/api/image/disystage/profilePicture/${this.props.userReducer.avatar}?w=300&q=20`}} style={{ width: 120, height: 120,borderRadius: 90, margin: 10 }} />
          }
          {/* <View style={{position: "absolute", margin:85, left: 0, backgroundColor: "#FFF", borderRadius: 45, width: 50, height: 50, alignItems:"center", }}>
            <View style={{position: "absolute",margin:5, backgroundColor: "red", borderRadius: 45, width: 40, height: 40, alignItems:"center", }}>
              <Image source={camara} style={{ width: 20, height: 20, marginTop: 8, tintColor: "white" }} />
            </View>
          </View> */}
        </View>
        <View style={styles.button}>
          <UploadImage
            getUrlImage={this.getUrlImage}
            image={this.state.imageServe}
            seeImage={false}
            nameSpace={"profilePicture"}
          />
        </View>
        {this.props.userReducer.type=="VENDEDOR"?
        <View style={styles.button}>
          <UploadImage
            getUrlImage={this.getUrlImageLogo}
            image={this.state.imageServe}
            seeImage={false}
            nameSpace={"profileLogo"}
            title="Cambiar logo"
          />
        </View>
        :null}
        {this.props.userReducer.type=="VENDEDOR"?
        <View style={styles.button}>
          <UploadImage
            getUrlImage={this.getUrlImagePortada}
            image={this.state.imageServe}
            seeImage={false}
            nameSpace={"profilePortada"}
            title="Cambiar portada"
          />
        </View>
        :null}
        <View>
          <Text style={styles.nombre}> 
            {this.props.userReducer.nombre + this.props.userReducer.apellidos}
          </Text>
          <Text style={styles.celular}>
            {this.props.userReducer.celular}
          </Text>
        </View>
        <View style={styles.button}>
          <Button icon='map' theme={{
              colors: { primary: "red", underlineColor: "transparent" }
            }} mode="contained" onPress={() => navigation.navigate("ajustesDetailsMap", { screen: "ajustesDetailsMap" })}>
            cambiar mi dirección
          </Button>
        </View>
        <View style={styles.button}>
          <Button icon="account-off" theme={{
                colors: { primary: "red", underlineColor: "transparent" }
              }} mode="contained" onPress={() => this.handleLogout()}>
            cerrar session
          </Button>
        </View>
        {/* {this.props.userReducer.type=="VENDEDOR"?
          <View>
            <Button icon="account-off" theme={{
                  colors: { primary: "red", underlineColor: "transparent" }
                }} mode="contained" onPress={() => logout()}>
              cerrar session
            </Button>
          </View>:
        null} */}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  nombre: {
    paddingTop: 10,
    fontSize: 24,
    fontWeight: '400'
  },
  celular: {
    paddingBottom: 15,
    fontSize: 18,
    fontWeight: '300'
  },
  searchIcon: {
    width: 65,
    height: 65
  },
  button: {
    margin: 5
  },  
  AvatarIcon: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    margin: 15,
    width: 120,
    height: 120,
    borderRadius: 90,
    backgroundColor:
      "rgb(" +
      Math.floor(Math.random() * 256) +
      "," +
      Math.floor(Math.random() * 256) +
      "," +
      Math.floor(Math.random() * 256) +
      ")"
  },
  container: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
  }
});

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer
  };
};

const mapDispatchToProps = { logout, updateAvarar, limpiarProduct, updateLogo, updatePortada };
export default connect(mapStateToProps, mapDispatchToProps)(setting);
