import * as React from 'react';
import { Button, Text, View, SafeAreaView } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import Productos from '../modules/Productos'
import Title from '../components/Title'
import AddProduct from '../modules/AddProduct'

function ProductosScreen({ navigation }) {
  return (
    <View style={{ flex: 1 }}>
      <Title />
      <Productos navigation={navigation} />
    </View>
  );
} 

function ProductosAddScreen({ navigation }) {
  return (
    <View style={{ flex: 1 }}>
      <Title />
      <AddProduct navigation={navigation} Type="ADD"/>
    </View>
  );
} 

function ProductosEditScreen({ navigation, route }) {
  return (
    <View style={{ flex: 1 }}>
      <Title />
      <AddProduct navigation={navigation} Type="EDIT" route={route}/>
    </View>
  );
} 

const HomeStack = createStackNavigator();

export default function ProductosStackScreen({ navigation }) {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen name="Productos" component={ProductosScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerTintColor: '#fff',
      }} />
      <HomeStack.Screen name="ProductosAdd" component={ProductosAddScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerTintColor: '#fff',
      }} />
      <HomeStack.Screen name="ProductosEdit" component={ProductosEditScreen} options={{
        headerShown: false,
        headerStyle: {
          borderColor: 'white',
          backgroundColor: 'red',
        },
        headerTintColor: '#fff',
      }} />
      </HomeStack.Navigator>
  );
}