import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from 'redux-thunk';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Reducer  from '../reducers'

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
};

const rootReducer = persistReducer(persistConfig, Reducer);

export const store = createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);