import * as React from 'react';
import {View} from 'react-native'
import { createStackNavigator } from '@react-navigation/stack';
import Login from '../modules/Login'
import Register from '../modules/Register'
import RegisterMap from '../modules/RegisterMap'
import RecordarCon from '../modules/RecordarCon'
// import Politicas from '../Views/Inicio/Politicas'
// import Recordar from '../Views/Inicio/Recordar'
// import Registro from '../Views/Inicio/Registro'
// import RegisterMapCom from '../components/RegisterMapCom'

const Stack = createStackNavigator();

export default function loginStackScreem({navigation}) {
  return (
    <Stack.Navigator initialRouteName="Login" >
      <Stack.Screen name="Login" component={Login} options={{
        headerTransparent: true,
        title: ''
      }} />
      <Stack.Screen name="Register" component={Register} options={{
        headerTransparent: true,
        title: ''
      }} />
      <Stack.Screen name="RegisterMap" component={RegisterMap} options={{
        headerTransparent: true,
        title: ''
      }}/>
      <Stack.Screen name="RecordarCon" component={RecordarCon} options={{
        headerTransparent: true,
        title: ''
      }}/>

    </Stack.Navigator>
  );
}