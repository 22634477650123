import { ERROR, TRAER_POR_ID_TIENDA_PRODUCTOS, LIMPIAR_PRODUCTOS, TRAER_POR_ID_TIENDA_PRODUCTOS_HEADER, CARD_LIST_ADD, CARD_LIST_DELETE,LIMPIAR_CART } from '../types/productos.types';

const INITIAL_STATE = {
	Productos: [],
	ProductosAdmin: [],
	busqueda: [],
	error: '',
	header: [],
	cardList: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TRAER_POR_ID_TIENDA_PRODUCTOS:
			return { ...state, Productos: action.payload, error: '' };
		case TRAER_POR_ID_TIENDA_PRODUCTOS_HEADER:
			return { ...state, header: action.payload }
		case ERROR:
			return { ...state, error: action.payload, }
		case LIMPIAR_PRODUCTOS:
			return { ...state, busqueda: [], Productos: [], header: [], error: '' }
		case CARD_LIST_ADD:
			return { ...state, cardList: [ ...state.cardList, action.payload] }
		case LIMPIAR_CART:
			return { ...state, cardList: state.cardList.filter( e => e.idTienda !== action.payload ) }
		case CARD_LIST_DELETE:
			return { ...state, cardList: state.cardList.filter( e => e.id !== action.payload ) }
		default: return state;
	};
}; 