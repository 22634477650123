import { LOGIN_USER, ERROR_USER, LOGOUT_CLEAR_USER, EDITAR_MARCADOR_REFERENCIAS_USER, EDITAR_MARCADOR_USER, UPDATE_AVATAR, SET_TOKEN_NOTIFICATION } from '../types/user.type'

const INITIAL_STATE = {
  email: '',
  token: '',
  session: false,
  localizacion: '',
  cargando: false,
  error: '',
  visible: false,
  avatar: '',
  celular: '',
  type: "",
  nombre: '',
  apellidos: "",
  marcador: {},
  referencias: "",
  idTienda:"",
  nameSpace:"",
  tokenNotification: "",
  isActive:""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        email: action.payload.email,
        token: action.payload.token,
        session: action.payload.session,
        avatar: action.payload.avatar,
        celular: action.payload.celular,
        nombre: action.payload.nombre,
        apellidos: action.payload.apellidos,
        marcador: action.payload.marcador,
        referencias: action.payload.referencias,
        type: action.payload.type,
        idTienda: action.payload.idTienda,
        nameSpace: action.payload.nameSpace,
        isActive: action.payload.isActive
      };
    case ERROR_USER:
      return { ...state, error: action.payload }
    case LOGOUT_CLEAR_USER:
      return {
        ...state, email: '',
        token: "",
        session: '',
        avatar: '',
        celular: '',
        nombre: '',
        apellidos: '',
        marcador: '',
        referencias: '',
        type: '',
        idTienda: '',
        nameSpace: '',
        isActive: ""
      }
    case EDITAR_MARCADOR_USER:
      return {...state, marcador: action.payload }
    case EDITAR_MARCADOR_REFERENCIAS_USER:
      return {...state, referencias: action.payload }
    case UPDATE_AVATAR:
      return {...state, avatar: action.payload}
    case SET_TOKEN_NOTIFICATION:
      return { ...state, tokenNotification: action.payload }
    default: return state
  }
}