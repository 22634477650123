import React, { Component } from "react";
import {
  RefreshControl,
  ScrollView,
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  Linking,
  ActivityIndicator
} from "react-native";
import { connect } from "react-redux";
import {
  Title,
  Headline,
  Paragraph,
  Button,
  Surface,
  FAB,
  Chip
} from "react-native-paper";
import Images from '../../components/Images'
import {limpiarProduct} from '../../actions/productos.actions'
import { traerPorIdTienda } from "../../actions/productos.actions";

export class homeDetails extends Component {
  async componentDidMount() {
    const { traerPorIdTienda, limpiarProduct } = this.props;
    await traerPorIdTienda(this.props.route.params.item._id);
  }
  componentWillUnmount() {
    const { limpiarProduct } = this.props;
    limpiarProduct();
  }
  state = {
    refreshing: false,
    select: "Todos",
    loadingImageHeader: true
  };
  onRefresh = async () => {
    const { traerPorIdTienda } = this.props;
    await traerPorIdTienda(this.props.route.params.item._id);
    this.setState({ refreshing: true });
    setTimeout(() => {
      this.setState({ refreshing: false });
    }, 2000);
  };
  dialCall = (number) => {
    let phoneNumber = '';
    if (Platform.OS === 'android') { phoneNumber = `tel:${number}`; }
    else {phoneNumber = `telprompt:${number}`; }
    Linking.openURL(phoneNumber);
  };
  render() {
    const {navigation} = this.props
    let cartCount = this.props.productosReducers.cardList.length? this.props.productosReducers.cardList.filter(i=> i.idTienda==this.props.route.params.item._id).length: null
    // console.log(this.state)
    // console.log(this.props.productosReducers.Productos)
    // console.log(`https://file.rubengarcia.me/api/image/disystage/${this.props.route.params.item.nameSpace}/${this.props.route.params.item.imgLogo}?w=500&q=40`)
    return (
      <>
        <ScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={this.onRefresh}
            />
          }
        >
          <View>
            <Images
              resizeMode="cover"
              style={{ width: null, resizeMode: "cover", height: 220 }}
              url={`https://file.rubengarcia.me/api/image/disystage/${this.props.route.params.item.nameSpace}/${this.props.route.params.item.imgLogo}?w=500&q=40`}
            />
          </View>
          <View style={styles.header}>
            <Surface style={styles.surfaceheader}>
              <Title>
                {this.props.route.params.item.Nombre} -{" "}
                {this.props.route.params.item.colonia}
              </Title>
              <Paragraph>{this.props.route.params.item.descripcion}</Paragraph>
              <Paragraph
                style={{ color: "blue" }}
                onPress={() => {
                  this.dialCall(this.props.route.params.item.celular);
                }}
              >
                {this.props.route.params.item.celular}
              </Paragraph>
            </Surface>
          </View>
          <ScrollView
            horizontal={true}
            style={{ paddingTop: 18 }}
            showsHorizontalScrollIndicator={false}
          >
            {this.props.productosReducers.header
              ? this.props.productosReducers.header.map((item, i) => {
                  return (
                    <View key={i} style={{ paddingRight: 5, paddingLeft: 5 }}>
                      {this.state.select === item ? (
                        <Chip
                          onPress={() => console.log("Pressed")}
                          selectedColor="white"
                          style={{ backgroundColor: "red" }}
                          mode="flat"
                          theme={{
                            colors: {
                              primary: "red",
                              underlineColor: "transparent"
                            }
                          }}
                        >
                          {item}
                        </Chip>
                      ) : (
                        <Chip
                          onPress={() => this.setState({ select: item })}
                          selectedColor="red"
                          mode="outlined"
                          theme={{
                            colors: {
                              primary: "red",
                              underlineColor: "transparent"
                            }
                          }}
                        >
                          {item}
                        </Chip>
                      )}
                    </View>
                  );
                })
              : null}
          </ScrollView>
          <View>
            {this.props.productosReducers.Productos
              ? this.props.productosReducers.Productos.filter(word =>
                word.categoria.find(cat => cat == this.state.select)
                ).map((item, i) => {
                  return (
                    <>
                      {item.disponible?
                      <View key={i+'1'}>
                        <View opacity={1.0}></View>
                        <TouchableOpacity
                          onPress={() =>
                            navigation.navigate("ProductosDetailsHome", {
                              screen: "ProductosDetailsHome",
                              item,
                              nameSpace: this.props.route.params.item.nameSpace
                            })
                          }
                          style={styles.button}
                        >
                          <Surface style={styles.surface}>
                            <View key={i} data-key={i} style={styles.card}>
                              <View style={styles.image}>
                                <Images
                                  resizeMode="cover"
                                  url= {`https://file.rubengarcia.me/api/image/disystage/${this.props.route.params.item.nameSpace}/${item.img}?w=150&q=50`}
                                  style={{
                                    width: 110,
                                    height: 110,
                                    borderTopLeftRadius: 10,
                                    borderBottomLeftRadius: 10,
                                    resizeMode: "cover"
                                  }}
                                />
                              </View>
                              <View style={styles.titles}>
                                <Headline>{item.Nombre}</Headline>
                                <Paragraph>{item.descripcion}</Paragraph>
                                <Paragraph>${item.precio}</Paragraph>
                              </View>
                            </View>
                          </Surface>
                        </TouchableOpacity>
                      </View>:
                      <View key={i}>
                        <Surface style={styles.surfaceAgotado}>
                          <View key={i} data-key={i} style={styles.card}>
                            <View style={styles.image}>
                              <Images
                                resizeMode="cover"
                                url={`https://file.rubengarcia.me/api/image/disystage/${this.props.route.params.item.nameSpace}/${item.img}?w=150&q=50`}
                                style={{
                                  width: 110,
                                  height: 110,
                                  borderTopLeftRadius: 10,
                                  borderBottomLeftRadius: 10,
                                  resizeMode: "cover"
                                }}
                              />
                            </View>
                            <View style={styles.titleAgotado}>
                              <Headline style={styles.titleAgotado}>{item.Nombre}</Headline>
                              <Paragraph style={styles.titleAgotado}>{item.descripcion}</Paragraph>
                              <Paragraph style={styles.titleAgotado}>${item.precio}</Paragraph>
                            </View>
                          </View>
                        </Surface>
                      </View>}
                    </>
                  );
                })
              : null}
            {this.state.select == "Todos"&&this.props.productosReducers.Productos.length
              ? this.props.productosReducers.Productos.map((item, i) => {
                  return (
                    <>
                    {item.disponible?
                    <View key={i}>
                      <View opacity={1.0}></View>
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate("ProductosDetailsHome", {
                            screen: "ProductosDetailsHome",
                            item,
                            nameSpace: this.props.route.params.item.nameSpace
                          })
                        }
                        style={styles.button}
                      >
                        <Surface style={styles.surface}>
                          <View key={i} data-key={i} style={styles.card}>
                            <View style={styles.image}>
                              <Images
                                resizeMode="cover"
                                url={`https://file.rubengarcia.me/api/image/disystage/${this.props.route.params.item.nameSpace}/${item.img}?w=150&q=50`}
                                style={{
                                  width: 110,
                                  height: 110,
                                  borderTopLeftRadius: 10,
                                  borderBottomLeftRadius: 10,
                                }}
                              />
                            </View>
                            <View style={styles.titles}>
                              <Headline>{item.Nombre}</Headline>
                              <Paragraph>{item.descripcion}</Paragraph>
                              <Paragraph>${item.precio}</Paragraph>
                            </View>
                          </View>
                        </Surface>
                      </TouchableOpacity>
                    </View>:
                    <View key={i}>
                        <Surface style={styles.surfaceAgotado}>
                          <View key={i} data-key={i} style={styles.card}>
                            <View style={styles.image}>
                              <Images
                                resizeMode="cover"
                                url={`https://file.rubengarcia.me/api/image/disystage/${this.props.route.params.item.nameSpace}/${item.img}?w=150&q=50`}
                                style={{
                                  width: 110,
                                  height: 110,
                                  opacity: 0.5,
                                  borderTopLeftRadius: 10,
                                  borderBottomLeftRadius: 10,
                                }}
                              />
                            </View>
                            <View style={styles.titleAgotado}>
                              <Headline style={styles.titleAgotado}>{item.Nombre}</Headline>
                              <Paragraph style={styles.titleAgotado}>{item.descripcion}</Paragraph>
                              <Paragraph style={styles.titleAgotado}>${item.precio}</Paragraph>
                            </View>
                          </View>
                        </Surface>
                    </View>}
                    </>
                  );
                })
              : null}
          </View>
          <View style={{padding:10}}></View>
        </ScrollView>
        {this.props.productosReducers.cardList.filter(i=> i.idTienda==this.props.route.params.item._id).length?
        <FAB
          style={styles.fab}
          icon="cart"
          visible={true}
          label={cartCount>0? `${cartCount}`: null}
          onPress={() =>
            navigation.navigate("modal", {
              screen: "modal",
              item: this.props.route.params.item,
              nameSpace: this.props.route.params.item.nameSpace
            })
          }
        />
        :null}
      </>
    );
  }
}
const styles = StyleSheet.create({
  titleAgotado: {
    marginLeft: 10,
    marginRight: 10,
    justifyContent: "flex-start",
    color: "gray"
  },
  surfaceheader: {
    borderBottomEndRadius: 10,
    borderBottomStartRadius: 10,
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    padding: 18
  },
  surface: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: 18,
    marginLeft: 18,
    marginRight: 18
  },
  surfaceAgotado: {
    opacity: 0.5,
    borderRadius: 10,
    marginTop: 18,
    marginLeft: 18,
    marginRight: 18
  },
  fab: {
    shadowOpacity: 22,
    borderRadius: 30,
    backgroundColor: "red",
    position: "absolute",
    bottom: 15,
    zIndex: 1,
    right: 10
  },
  fabs: {
    shadowOpacity: 22,
    borderRadius: 30,
    color: "white",
    position: "absolute",
    bottom: 50,
    right: 22,
    zIndex: 2
  },
  cancelar: {
    color: "white",
    backgroundColor: "#FFF6F4"
  },
  ordenar: {
    color: "white",
    backgroundColor: "red"
  },
  header: {},
  textRegister: {
    flexDirection: "row",
    textAlign: "center",
    color: "gray"
  },
  Lista_de_pedidos: {
    flexDirection: "row",
    textAlign: "center",
    color: "gray",
    paddingTop: 22
  },
  card: {
    flexDirection: "row",
  },
  titles: {
    marginLeft: 10,
    marginRight: 10,
    justifyContent: "flex-start"
  },
  lineStyle: {
    backgroundColor: "#A2A2A2",
    height: 2,
    width: 165
  }
});
const mapStateToProps = ({ productosReducers, userReducer }) => {
  return {
    productosReducers,
    userReducer
  };
};

const mapDispatchToProps = {
  traerPorIdTienda,
  limpiarProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(homeDetails);
