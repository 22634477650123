import { combineReducers } from 'redux';
import userReducer from './UserReducers'
import tiendasReducers from './tiendasReducers'
import productosReducers from './productosReducers'
import ordenReducers from './ordenReducers'
export default combineReducers({
  userReducer,
  tiendasReducers,
  productosReducers,
  ordenReducers
});
