import React, { Component } from 'react'
import {Text, View,StyleSheet, ScrollView, RefreshControl, TouchableOpacity } from 'react-native'
import {connect} from 'react-redux'
import Time from '../../components/Time'
import io from 'socket.io-client'
import {orderById, addOrden, seeDetailsOrden} from '../../actions/orden.actions'
import * as Notifications from 'expo-notifications';
export class pedidos extends Component {
  state={
    data:{
      mensage:"",
    },
    refreshing: false,
    ordenes: [],
    socketState: false
  }
  async componentDidMount() {
    this.setState({refreshing:true});
    let {orderById, addOrden, seeDetailsOrden} = this.props;
    await orderById(this.props.userReducer.token)
    this.setState({refreshing:false});

    var socket = io.connect('https://api.disy.com.mx', {
      query: {
        token: this.props.userReducer.token
      }
    });
    // socket.on('connect', () => this.setState(socket.connected ? {socketState:true} : {socketState:false}));
    // socket.on('disconnect', () => this.setState(socket.connected ? {socketState:true} : {socketState:false}));
    socket.on(this.props.userReducer.email, async(date) => {
      if(date.statusventa=="PENDIENTE"){
        await Notifications.scheduleNotificationAsync({
          content: {
            title: "Disy",
            body: 'Nuevo Pedido!!',
          },
          trigger: null
        });
      } else if (date.statusventa=="ENVIADO") {
        await Notifications.scheduleNotificationAsync({
          content: {
            title: "Disy",
            body: 'Pedido enviado',
          },
          trigger: null
        });
      } else if (date.statusventa=="ACEPTADA") {
        await Notifications.scheduleNotificationAsync({
          content: {
            title: "Disy",
            body: 'Pedido acetado por el local',
          },
          trigger: null
        });
      } else if (date.statusventa=="CANCELADA") {
        await Notifications.scheduleNotificationAsync({
          content: {
            title: "Disy",
            body: 'Pedido Cancelado',
          },
          trigger: null
        });
      }
      Number.parseInt(this.props.ordenReducers.item.nomOrden)==Number.parseInt(date.nomOrden)?await seeDetailsOrden(date): null
      // let finddate = ordenes.find(element => date._id === element._id)
      // if (finddate) {
      //   console.log(ordenes.filter(i=> i._id !== date._id).length)
      // } else {
      //   console.log(ordenes.filter(i=> i._id !== date._id))
      //   addOrden([date, ...ordenes].sort((a, b) => a.nomOrden - b.nomOrden).reverse())
      // }
      // // console.log(this.props.ordenReducers.ordenes.length)
      // let filter = this.props.ordenReducers.ordenes.filter(i=> i._id !== date._id)
      await addOrden(date)
    });
  }
  onRefresh = async () => {
    this.setState({refreshing:true});
    const {orderById} = this.props;
    await orderById(this.props.userReducer.token)
    this.setState({refreshing:false});
  }
  ordenTotal = (productos) => {
    let total = 0;
    productos.forEach(item => {
      total = total + item.precio;
    });
    return total;
  };
  seeDetails = async (item, i) => {
    const {navigation, seeDetailsOrden} = this.props
    await seeDetailsOrden(item)
    navigation.navigate("pedidosDetails", { screen: "pedidosDetails"})
  }
  render() {
    return (
      <>
        <View>
          <Text></Text>
        </View>
        <ScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={
            <RefreshControl refreshing={this.state.refreshing} onRefresh={this.onRefresh}/>
          }
          >
          {/* <Text>{this.state.socketState?"conectado":"desconectado"}</Text> */}
          {this.props.ordenReducers.ordenes.length?this.props.ordenReducers.ordenes.map((item,i) => {
            return(
              <View key={i}>
                <TouchableOpacity onPress={() => this.seeDetails(item, i)}>
                  <Time item={item}/>
                </TouchableOpacity>
              </View>
            )
          }):null}
        <Text></Text>
        </ScrollView>
      </>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    flexDirection: 'row',
  },
  icon: {
    padding:18,
  },
  text:{
    padding:10
  },
  card:{
    marginTop: 18,
    marginLeft: 18,
    marginRight: 18,
  }
})

const mapStateToProps = ({userReducer, ordenReducers}) => {
  return {userReducer, ordenReducers}
}
const mapDispatchToProps = {
  orderById,
  addOrden,
  seeDetailsOrden
}

export default connect(mapStateToProps, mapDispatchToProps)(pedidos)
