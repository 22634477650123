import React, { Component } from 'react'
import { Text, View , ScrollView, RefreshControl, TouchableOpacity} from 'react-native'
import {OrdenByIdTienda, addOrden, seeDetailsOrden} from '../../actions/orden.actions'
import { connect } from 'react-redux'
import OrdenItem from '../../components/OrdenItem'
import io from 'socket.io-client'
export class Ordenes extends Component {
  state={
    refreshing: false
  }
  async componentDidMount() {
    this.setState({refreshing:true});
    let {OrdenByIdTienda, addOrden, seeDetailsOrden} = this.props;
    await OrdenByIdTienda(this.props.userReducer.token)
    this.setState({refreshing:false});

    var socket = io.connect('https://api.disy.com.mx', {
      query: {
        token: this.props.userReducer.token
      }
    });
    // socket.on('connect', () => this.setState(socket.connected ? {socketState:true} : {socketState:false}));
    // socket.on('disconnect', () => this.setState(socket.connected ? {socketState:true} : {socketState:false}));
    socket.on(this.props.userReducer.idTienda, async(date) => {
      Number.parseInt(this.props.ordenReducers.item.nomOrden)==Number.parseInt(date.nomOrden)?await seeDetailsOrden(date): null
    //   item.nomOrden==date.nomOrden?seeDetailsOrden(date): null
    //   addOrden([date, ...ordenes.filter(i=> i._id !== date._id)].sort((a, b) => a.nomOrden - b.nomOrden).reverse())
    //   // let finddate = ordenes.find(element => date._id === element._id)
    //   // if (finddate) {
    //   // } else {
    //   //   addOrden([date, ...ordenes].sort((a, b) => a.nomOrden - b.nomOrden).reverse())
    //   // }
    // console.log(this.props.ordenReducers.ordenes.length)
    await addOrden(date)
    });
    if(this.props.userReducer.idTienda==""){
      socket.disconnect()
      socket.emit('forceDisconnect');
      console.log('disconect')
      socket.close()
    }
  }
  onRefresh = async () => {
    this.setState({refreshing:true});
    const {OrdenByIdTienda, userReducer: {token}} = this.props;
    await OrdenByIdTienda(token)
    this.setState({refreshing:false});
  }
  seeDetails = async (item, i) => {
    const {navigation, seeDetailsOrden} = this.props
    await seeDetailsOrden(item)
    navigation.navigate("OrdenesDetails", { screen: "OrdenesDetails", item, i})
  }
  render() {
    return (
      <ScrollView 
        refreshControl={
          <RefreshControl refreshing={this.state.refreshing} onRefresh={this.onRefresh}/>
        }
      >
        <View>
        {this.props.ordenReducers.ordenes.length?this.props.ordenReducers.ordenes.map((item,i) => {
          return(
            <View key={i}>
              <TouchableOpacity onPress={() => this.seeDetails(item, i)}>
                <OrdenItem item={item}/>
              </TouchableOpacity>
            </View>
          )
        }):null}
        </View>
      </ScrollView>
    )
  }
}
const mapStateToProps = ({userReducer, ordenReducers}) => ({
  userReducer,
  ordenReducers
})

const mapDispatchToProps = {
  OrdenByIdTienda,
  addOrden,
  seeDetailsOrden
}


export default connect(mapStateToProps, mapDispatchToProps)(Ordenes)