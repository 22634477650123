import {ERROR, TRAER_TODOS, BUSCADOR} from '../types/tiendas.types';

const INITIAL_STATE = {
	Tiendas: [],
	busqueda: [],
	patrocinados: [],
	cargando: false,
	error: '',
	ordens: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TRAER_TODOS:
			return { ...state, Tiendas: action.payload.locales.todos, patrocinados: action.payload.locales.patrocinados, cargando: false, error: '' };
		case ERROR:
			return { ...state, error: action.payload, cargando: false }
		case BUSCADOR:
			return { ...state, busqueda: action.payload,  cargando: false, error: '' }
		default: return state;
	};
};