import React, { Component } from 'react'
import { View, StyleSheet, Image, ScrollView, Text, Linking } from "react-native";
import { Headline, Paragraph, Surface, Button, Title, ActivityIndicator } from "react-native-paper";
import { editEstatusOrden, userByOrden } from '../../actions/orden.actions'
import {tiendaById} from '../../actions/tiendas.actions'
import Mapa from "../../../assets/mapa.png";
import moment from 'moment'
import enviado from "../../../assets/enviado.png";
import cancelar from "../../../assets/cancelar.png";
import aceptado from "../../../assets/aceptado.png";
import { connect } from 'react-redux'
import Images from "../../components/Images"
export class pedidosDetails extends Component {
  state = {
    productList: [],
    visible: false,
    loadding: false,
    region: {},
    dialog: false,
    minute: '00',
    second: '00',
    duracion: 3,
    loaddingEdit: false,
    user: null,
    local: {}
  };
  async componentDidMount() {
    const {userByOrden, tiendaById} = this.props
    const { item } = this.props.ordenReducers
    let user = null
    item.statusventa == "ACEPTADA"||item.statusventa == "ENVIADO"||item.statusventa == "PENDIENTE"?
    user = await userByOrden(item._id, this.props.userReducer.token): null
    this.setState({user})
    
    let tiendaByIdData = await tiendaById(item.idTienda ,this.props.userReducer.token)
    this.setState({
      local: {
        img: tiendaByIdData.imgLogo,
        title: tiendaByIdData.Nombre,
        celular: tiendaByIdData.celular
      }
    })
    let diffse = moment(item.created_at).add(this.state.duracion, "minute").diff(moment(new Date()), 's')
    let diffmi = moment(item.created_at).add(this.state.duracion, "minute").diff(moment(new Date()), 'minute');
    this.setState({ minute: diffmi, second: diffse })
    let refrehCount = setInterval(() => {
      let segundos = moment(item.created_at).add(this.state.duracion, "minute").diff(moment(new Date()), 's')
      this.setState({
        minute: `0${moment(item.created_at).add(this.state.duracion, "minute").diff(moment(new Date()), 'minute')}`, 
        second: segundos
      })
      if (segundos<=0) {
        clearInterval(refrehCount);
      }
    }, 1000);
  }
  async EditOrdenAceptada(status) {
    this.setState({ loaddingEdit: true })
    const { editEstatusOrden, userReducer: { token } } = this.props
    const { _id,idTienda } = this.props.ordenReducers.item
    await editEstatusOrden(token, { statusventa: status, idOrden: _id })
    await tiendaById(idTienda ,this.props.userReducer.token)
    this.setState({ loaddingEdit: false })
  }
  async EditOrdenCancelada() {
    this.setState({ loaddingEdit: true })
    const { editEstatusOrden, userReducer: { token } } = this.props
    const { _id, idTienda } = this.props.ordenReducers.item
    await editEstatusOrden(token, { statusventa: "CANCELADA", idOrden: _id })
    await tiendaById(idTienda ,this.props.userReducer.token)
    this.setState({ loaddingEdit: false })
  }
  dialCall = (number) => {
    let phoneNumber = '';
    if (Platform.OS === 'android') { phoneNumber = `tel:${number}`; }
    else {phoneNumber = `telprompt:${number}`; }
    Linking.openURL(phoneNumber);
  };
  openGps = (lat, lng) => {
    // var scheme = Platform.OS === 'ios' ? 'maps:' : 'geo:';
    // var url = scheme + `${lat},${lng}`;

    let scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
    let latLng = `${lat},${lng}`;
    let label = `${this.state.user.nombre} ${this.state.user.apellidos}`;
    let url = Platform.select({
      ios: `${scheme}${label}@${latLng}`,
      android: `${scheme}${latLng}(${label})`
    });
    Linking.openURL(url);
  }
  render() {
    console.log(this.props.ordenReducers.item)
    let { productos, nameSpace } = this.props.ordenReducers.item
    let total = 0
    var statusventa = this.props.ordenReducers.item.statusventa
    const { navigation } = this.props
    productos.map(i => {
      total = total + i.precio
    })
    console.log(`https://file.rubengarcia.me/api/image/disystage/profileLogo/${this.state.local.img}?w=300&q=60`)
    return (
      <ScrollView>
        <View style={styles.icon}>
          {statusventa == "ENVIADO" ? (
            <Image source={enviado} style={{ width: 90, height: 90 }} />
          ) : null}
          {statusventa == "ACEPTADA" ? (
            <Image source={aceptado} style={{ width: 90, height: 90 }} />
          ) : null}
          {statusventa == "CANCELADA" ? (
            <Image source={cancelar} style={{ width: 90, height: 90 }} />
          ) : null}
        </View>
        <View>
          {statusventa == "PENDIENTE" ? (
            <ActivityIndicator animating={true} color={"red"} size={90} />
          ) : null}
        </View>
        {this.state.second > 0&&(this.state.minute==0 || statusventa == "PENDIENTE") ?
          <View style={styles.borrar}>
            <Text style={styles.temp}>0{Math.abs(this.state.minute)}:</Text>
            <Text style={styles.temp}>
              {this.state.second % 0x3b>=10?`${this.state.second % 0x3b}`:null}
              {this.state.second % 0x3b==0?`0${this.state.second % 0x3b}`:null}
              {this.state.second % 0x3b==1?`0${this.state.second % 0x3b}`: null}
              {this.state.second % 0x3b==2?`0${this.state.second % 0x3b}`: null}
              {this.state.second % 0x3b==3?`0${this.state.second % 0x3b}`: null}
              {this.state.second % 0x3b==4?`0${this.state.second % 0x3b}`: null}
              {this.state.second % 0x3b==5?`0${this.state.second % 0x3b}`: null}
              {this.state.second % 0x3b==6?`0${this.state.second % 0x3b}`: null}
              {this.state.second % 0x3b==7?`0${this.state.second % 0x3b}`: null}
              {this.state.second % 0x3b==8?`0${this.state.second % 0x3b}`: null}
              {this.state.second % 0x3b==9?`0${this.state.second % 0x3b}`: null}
            </Text>
          </View>
        : null}
        {/* : <Text>111</Text>} */}
        {this.state.user!=null&&(statusventa == "ACEPTADA"||statusventa == "ENVIADO"||statusventa == "PENDIENTE")&&this.props.userReducer.type=="CLIENTE"?
        <View style={styles.userCenter}>
          <View style={styles.userRow}>
            <Images
              resizeMode="cover"
              style={{ width: 70, height: 70,borderRadius: 45 }}
              url={`https://file.rubengarcia.me/api/image/disystage/profileLogo/${this.state.local.img}?w=300&q=60`}
            />
            <View style={styles.userCol}>
              <Text style={styles.userText}>{this.state.local.title}</Text>
              <Paragraph
                style={{ color: "blue" }}
                onPress={() => {
                  this.dialCall(this.state.local.celular);
                }}
              >{this.state.local.celular}</Paragraph>
            </View>
          </View>
        </View>
        :null}
        {this.state.user!=null&&(statusventa == "ACEPTADA"||statusventa == "ENVIADO"||statusventa == "PENDIENTE")&&this.props.userReducer.type=="VENDEDOR"?
        <View style={styles.userCenter}>
          <View style={styles.userRow}>
            <Images
              resizeMode="cover"
              style={{ width: 70, height: 70,borderRadius: 45 }}
              url={`https://file.rubengarcia.me/api/image/disystage/profilePicture/${this.state.user.avatar}?w=300&q=20`}
            />
            <View style={styles.userCol}>
              <Text style={styles.userText}>{this.state.user.nombre} {this.state.user.apellidos}</Text>
              <Paragraph
                style={{ color: "blue" }}
                onPress={() => {
                  this.dialCall(this.state.user.celular);
                }}
              >{this.state.user.celular}</Paragraph>
            </View>
          </View>
        </View>
        :null}
        {this.props.userReducer.type == "VENDEDOR" && this.props.ordenReducers.item.statusventa == "PENDIENTE" ?
        <View style={styles.buttons}>
          <Button mode="contained" style={{margin: 5}} loading={this.state.loaddingEdit} disabled={this.state.loaddingEdit} color='red' onPress={()=> this.EditOrdenAceptada("ACEPTADA")}>
            Aceptar
          </Button>
          <Button mode="outlined" style={{margin: 5}} loading={this.state.loaddingEdit} disabled={this.state.loaddingEdit} color='red' onPress={()=>  this.EditOrdenCancelada()}>
            cancelar
          </Button>
        </View>
        : null}
        {this.props.userReducer.type == "VENDEDOR" && this.props.ordenReducers.item.statusventa == "ACEPTADA" ?
        <View style={styles.buttons}>
          <Button mode="contained" style={{margin: 5}} loading={this.state.loaddingEdit} disabled={this.state.loaddingEdit} color='red' onPress={()=> this.EditOrdenAceptada("ENVIADO")}>
            enviar
          </Button>
          <Button mode="outlined" style={{margin: 5}} loading={this.state.loaddingEdit} disabled={this.state.loaddingEdit} color='red' onPress={()=> this.EditOrdenCancelada()}>
            cancelar
          </Button>
        </View>
        : null}
        {productos.map((i, index) => (
          <View key={index}>
            <View opacity={1.0}></View>
            <Surface style={styles.surface}>
              <View style={styles.card}>
                <View style={styles.align}>
                  <View style={styles.image}>
                    <Images
                      resizeMode="cover"
                      url={`https://file.rubengarcia.me/api/image/disystage/${nameSpace}/${i.img}?w=150&q=50`}
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: 10
                      }}
                    />
                  </View>
                  <View style={styles.titles}>
                    <Headline>{i.Nombre}</Headline>
                    <Paragraph>{i.descripcion}</Paragraph>
                    <Paragraph>cantindads: {i.cantidad}</Paragraph>
                    <Paragraph>c/u: ${i.precio.toFixed(2)}</Paragraph>
                  </View>
                </View>
              </View>
            </Surface>
          </View>
        ))}
        {this.props.userReducer.type == 'VENDEDOR'&&statusventa!='CANCELADA'?
        <View style={styles.direccion}>
          <Image
            source={Mapa}
            style={{ width: null, resizeMode: "cover", height: 60, width: 60 }}
            />
          <View style={{ alignItems: 'flex-start' }}>
            <Title>Enviar a direccion guardada</Title>
            <Button mode="text" color='red' onPress={e => this.openGps(this.props.ordenReducers.item.marcador.latitude, this.props.ordenReducers.item.marcador.longitude)}>
              Ver
            </Button>
          </View>
        </View>
        :null}
        {statusventa!='CANCELADA'?
        <View>
          <View style={styles.envio}>
            <Title>Envio: Gratis</Title>
          </View>
          <View style={styles.total}>
            <Title>Total: ${total.toFixed(2)}</Title>
          </View>
        </View>
        :null}
      </ScrollView>
    )
  }
}

const styles = StyleSheet.create({
  userText: {
    fontSize: 16,
    fontWeight: '600'
  },
  userCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 12
  },
  userRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  userCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  temp: {
    fontSize: 18,
    fontWeight: '600'
  },
  direccion: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10
  },
  envio: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 20,
    paddingRight: 20
  },
  total: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  centrar: {
    display: 'flex',
    alignItems: 'center'
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "center",
    overlayColor: "red",
  },
  icon: {
    padding: 18,
    position: 'relative',
    left: '33%'
  },
  borrar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'center',
    paddingTop: 10
  },
  surface: {
    borderRadius: 10,
    marginTop: 18,
    marginLeft: 18,
    marginRight: 18
  },
  align: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 10,
    marginLeft: 10,
    marginRight: 10
  },
  card: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  titles: {
    marginLeft: 10,
    marginRight: 10,
    justifyContent: "flex-start"
  },
  image: {
    borderRadius: 22,
    justifyContent: 'center'
  }
});

const mapStateToProps = ({ ordenReducers, userReducer }) => {
  return {
    ordenReducers,
    userReducer
  };
};

const mapDispatchToProps = {
  editEstatusOrden,
  userByOrden,
  tiendaById
};

export default connect(mapStateToProps, mapDispatchToProps)(pedidosDetails)
